import { useMemo } from 'react';

import { useOrganization } from '@/context/organization/useOrganization';
import notEmpty from '@/utils/notEmpty';

export default function useAllResourceIds() {
  const { organization, organizationLocations } = useOrganization();

  const allFQRNs = useMemo(
    () =>
      [organization?.fqrn ?? '', ...(organizationLocations?.map(location => location?.fqrn ?? '') ?? [])].filter(
        notEmpty
      ),
    [organization, organizationLocations]
  );

  return allFQRNs;
}

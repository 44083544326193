import * as React from 'react';

import { OrganizationContext } from './';

export const useCurrentOrganizationId = () => {
  const context = React.useContext(OrganizationContext);

  if (!context) {
    throw new Error('`useCurrentOrganizationId` must be used inside of a `OrganizationProvider`.');
  }

  return {
    nukeFromOrbit: context.nukeFromOrbit,
    organizationId: context.organizationId,
    selectOrganization: context.selectOrganization,
  };
};

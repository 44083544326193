import { SelectableAccountRole } from './roles';

// Types

export type Resource = 'organizations';

export const PermissionState = {
  AUTHORIZED: 'AUTHORIZED',
  PENDING: 'PENDING',
  UNAUTHORIZED: 'UNAUTHORIZED',
} as const;

export type AuthPermission = typeof PermissionState[keyof typeof PermissionState];

export const permissionKeys = [
  '*',
  'authz.permission.create',
  'authz.permission.delete',
  'authz.permission.list',
  'authz.policy.create',
  'authz.policy.delete',
  'authz.policy.update',
  'authz.policy.view',
  'authz.role.create',
  'authz.role.delete',
  'authz.role.list',
  'authz.role.update',
  'authz.role.view',
  'content.playlist.update',
  'content.relationships.create',
  'content.relationships.delete',
  'content.relationships.list_all',
  'content.relationships.update',
  'content.relationships.view',
  'developer.feature',
  'metrics.insights.view',
  'organizations.*',
  'organizations.accounts.create',
  'organizations.accounts.delete',
  'organizations.accounts.list',
  'organizations.accounts.update',
  'organizations.accounts.view',
  'organizations.church.create_location',
  'organizations.church.delete',
  'organizations.church.list_all',
  'organizations.church.update',
  'organizations.church.verify',
  'organizations.church.view',
  'organizations.create',
  'organizations.delete',
  'organizations.events.create',
  'organizations.events.delete',
  'organizations.events.list_all',
  'organizations.events.update',
  'organizations.events.view',
  'organizations.featured_plan.create',
  'organizations.featured_plan.delete',
  'organizations.images.upload',
  'organizations.list_all',
  'organizations.posts.create',
  'organizations.posts.delete',
  'organizations.posts.update',
  'organizations.profiles.bible.create',
  'organizations.profiles.bible.delete',
  'organizations.profiles.bible.list_all',
  'organizations.profiles.bible.update',
  'organizations.profiles.church.create',
  'organizations.profiles.church.delete',
  'organizations.profiles.church.list_all',
  'organizations.profiles.church.update',
  'organizations.profiles.church.view',
  'organizations.profiles.content.create',
  'organizations.profiles.content.delete',
  'organizations.profiles.content.list_all',
  'organizations.profiles.content.update',
  'organizations.profiles.content.view',
  'organizations.schedule.update',
  'organizations.sign_content_license_agreement',
  'organizations.update',
  'organizations.view',
  'users.accounts.create',
  'users.accounts.delete',
  'users.accounts.list',
  'users.accounts.update',
  'users.accounts.view',
  'users.user.view_all',
  'developer.feature',
] as const;

export type PermissionKeys = typeof permissionKeys;
export type PermissionKey = PermissionKeys[number];

export const AccountRolePermissions = {
  ADD_FEATURED_PLAN: 'ADD_FEATURED_PLAN',
  ADD_LOCATIONS: 'ADD_LOCATIONS',
  ADD_OR_DELETE_LOCATIONS: 'ADD_OR_DELETE_LOCATIONS',
  ADD_OR_EDIT_ACCOUNT_ROLES: 'ADD_OR_EDIT_ACCOUNT_ROLES',
  CREATE_POST: 'CREATE_POST',
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
  DELETE_LOCATION: 'DELETE_LOCATION',
  SIGN_CONTRACT: 'SIGN_CONTRACT',
  VIEW_AND_EDIT_PROFILE: 'VIEW_AND_EDIT_PROFILE',
  VIEW_INSIGHTS: 'VIEW_INSIGHTS',
  VIEW_PROFILE: 'VIEW_PROFILE',
} as const;

export type Permission = {
  /** Permission key to check for. */
  permissionKey: PermissionKey;
  /** Resources to check permission key against. */
  resources: Array<string | null | undefined>;
};

export type AccountRolePermissionsList = {
  [Key in SelectableAccountRole]: Array<typeof AccountRolePermissions[keyof typeof AccountRolePermissions]>;
};

// Roles
export const basePermissions = {
  editor: [AccountRolePermissions.VIEW_INSIGHTS],
  insightsViewer: [],
  owner: [AccountRolePermissions.VIEW_INSIGHTS, AccountRolePermissions.ADD_OR_EDIT_ACCOUNT_ROLES],
  staffRepresentative: [AccountRolePermissions.VIEW_INSIGHTS, AccountRolePermissions.ADD_OR_EDIT_ACCOUNT_ROLES],
};

// This profilePermissions is separate from `baseProfilePermissions` because
// bible publishers do not have access to `/profile` page as of July 25, 2023.
export const profilePermissions: AccountRolePermissionsList = {
  editor: [AccountRolePermissions.VIEW_AND_EDIT_PROFILE],
  insightsViewer: [],
  owner: [AccountRolePermissions.VIEW_AND_EDIT_PROFILE, AccountRolePermissions.SIGN_CONTRACT],
  staffRepresentative: [AccountRolePermissions.VIEW_AND_EDIT_PROFILE],
};

// The `VIEW_AND_EDIT_PROFILE` permission is in both `profilePermissions` and church permissions because
// both types have access to profile.
export const singleChurchLocationAccountRolePermissions: AccountRolePermissionsList = {
  editor: [
    AccountRolePermissions.ADD_FEATURED_PLAN,
    AccountRolePermissions.CREATE_POST,
    AccountRolePermissions.VIEW_AND_EDIT_PROFILE,
  ],
  insightsViewer: [],
  owner: [
    AccountRolePermissions.ADD_FEATURED_PLAN,
    AccountRolePermissions.CREATE_POST,
    AccountRolePermissions.DELETE_LOCATION,
    AccountRolePermissions.VIEW_AND_EDIT_PROFILE,
  ],
  staffRepresentative: [
    AccountRolePermissions.ADD_FEATURED_PLAN,
    AccountRolePermissions.CREATE_POST,
    AccountRolePermissions.DELETE_LOCATION,
    AccountRolePermissions.VIEW_AND_EDIT_PROFILE,
  ],
};

export const allChurchLocationsAccountRolePermissions: AccountRolePermissionsList = {
  editor: [AccountRolePermissions.ADD_LOCATIONS],
  insightsViewer: [],
  owner: [AccountRolePermissions.DELETE_ACCOUNT, AccountRolePermissions.ADD_OR_DELETE_LOCATIONS],
  staffRepresentative: [AccountRolePermissions.DELETE_ACCOUNT, AccountRolePermissions.ADD_OR_DELETE_LOCATIONS],
};

/** The permission key necessary for updating roles and bindings. */
export const roleEditPermission = 'authz.policy.update';

/* eslint-disable sort-keys */
/* eslint-disable typescript-sort-keys/interface */

import { getToken } from '@youversion/auth';
import { getCookies } from 'cookies-next';
import { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import { UAParser } from 'ua-parser-js';
import { v4 as uuidv4 } from 'uuid';

import dayjs from '@/lib/dayjs';
import { IS_STAGING, YV_AUTH_CLIENT_ID, YV_AUTH_CLIENT_SECRET } from '@/utils/env';

const GEO_HOST = 'https://geo.life.church/where';

enum EventType {
  Heartbeat = 'com.youversion.portal.heartbeat.v1',
  PageView = 'com.youversion.portal.page_view.v1_1',
  SessionStart = 'com.youversion.portal.session_start.v1_1',
  UserActionError = 'com.youversion.portal.user_action_error.v1',
  UserActionStart = 'com.youversion.portal.user_action_start.v1',
  UserActionSucceed = 'com.youversion.portal.user_action_succeed.v1',
}

interface BaseCloudEventData {
  datacontenttype: 'application/json';
  id: string;
  source: string;
  specversion: '0.2'; // According to the docs, specversion should be 1.0.2 but it's not working
  subject: string;
  time: string;
  type: EventType;
}

interface BaseData {
  organization_id: string | null;
  session_id: string;
  reporting_lag: number;
  user_id?: number;
  browser_id: string;
  device: {
    brand_name?: string;
    operating_system?: string;
    language: string;
    browser?: string;
    browser_version?: string;
  };
  geo: {
    country: string;
    city: string;
    region: string;
  };
  app_info: {
    id: string;
    version: string;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface MetricsSchema<T extends EventType, D extends Record<string, any> = Record<string, unknown>>
  extends BaseCloudEventData {
  data: BaseData & D;
  type: T;
}

interface HeartbeatData {
  interval: number;
}
type HeartbeatEvent = MetricsSchema<EventType.Heartbeat, HeartbeatData>;

interface PageViewData {
  name: string;
  url: string;
  path: string;
  title: string;
  referrer: string;
}
type PageViewEvent = MetricsSchema<EventType.PageView, PageViewData>;

interface SessionStartData {
  utm: {
    source: string;
    medium: string;
    campaign: string;
    term: string;
    content: string;
    ref: string;
  };
}
type SessionStartEvent = MetricsSchema<EventType.SessionStart, SessionStartData>;

export enum UserAction {
  CelebrationPageNavigationBanner = 'celebration_page_navigation_banner',
  CelebrationPageNavigationSidebar = 'celebration_page_navigation_sidebar',
  DownloadCelebrationImage = 'download_celebration_image',
  OrganizationsChurchCreate = 'organizations_church_create',
  OrganizationsChurchPublish = 'organizations_church_publish',
}

interface UserActionData {
  action: UserAction;
  object_id?: string;
}

interface UserActionErrorData extends UserActionData {
  message: string;
}
type UserActionErrorEvent = MetricsSchema<EventType.UserActionError, UserActionErrorData>;
type UserActionStartEvent = MetricsSchema<EventType.UserActionStart, UserActionData>;
type UserActionSucceedEvent = MetricsSchema<EventType.UserActionSucceed, UserActionData>;

type MetricsEvent =
  | HeartbeatEvent
  | PageViewEvent
  | SessionStartEvent
  | UserActionErrorEvent
  | UserActionStartEvent
  | UserActionSucceedEvent;

const getStagingEvent = (event: EventType) => {
  const eventParts = event.split('.');
  eventParts[2] = `${eventParts[2]}_staging`;
  return eventParts.join('.');
};

const sendEvent = (event: MetricsEvent) => {
  const url = `https://${IS_STAGING ? 'staging.' : ''}metricsengine.io/topics/metrics-ingest/publish`;
  const body = JSON.stringify({
    ...event,
    type: IS_STAGING ? getStagingEvent(event.type) : event.type,
  });

  if (navigator.sendBeacon) {
    navigator.sendBeacon(url, body);
  } else {
    fetch(url, { body, keepalive: true, method: 'POST' });
  }
};

const getUserId = async () => {
  const token = await getToken({
    clientId: YV_AUTH_CLIENT_ID,
    clientSecret: YV_AUTH_CLIENT_SECRET,
    isStaging: IS_STAGING,
  });
  return token?.userId;
};

const getBaseCloudEventData = async (): Promise<Omit<BaseCloudEventData, 'type'>> => {
  const userId = await getUserId();
  return {
    datacontenttype: 'application/json',
    id: uuidv4(),
    source: window.location.origin,
    specversion: '0.2',
    subject: userId ? userId.toString() : '',
    time: dayjs().toISOString(),
  };
};

const getGeoData = async (): Promise<BaseData['geo']> => {
  const geoLocalStorage = localStorage.getItem('geo');
  if (!geoLocalStorage) {
    const response = await fetch(GEO_HOST, { method: 'GET' });
    const json = await response.json();
    const { country_code = null, city = null, region = null } = json;
    const geo = {
      country: country_code,
      city,
      region,
    };
    localStorage.setItem('geo', JSON.stringify(geo));
    return geo;
  } else {
    return JSON.parse(geoLocalStorage);
  }
};

const getBaseData = async (reportingLag = 0): Promise<BaseData> => {
  let sessionId = sessionStorage.getItem('SESSION_ID');
  if (!sessionId) {
    sessionId = uuidv4();
    sessionStorage.setItem('SESSION_ID', sessionId);
  }

  let browserId = localStorage.getItem('BROWSER_ID');
  if (!browserId) {
    browserId = uuidv4();
    localStorage.setItem('BROWSER_ID', browserId);
  }

  const { organization_id: organizationId } = getCookies();

  const ua = new UAParser().getResult();
  const userId = await getUserId();
  const geo = await getGeoData();

  return {
    organization_id: organizationId ?? null,
    session_id: sessionId,
    reporting_lag: reportingLag,
    user_id: userId,
    browser_id: browserId,
    device: {
      brand_name: ua.device.vendor,
      operating_system: ua.os.name,
      language: navigator.language,
      browser: ua.browser.name,
      browser_version: ua.browser.version,
    },
    geo,
    app_info: {
      id: 'portal',
      version: '1',
    },
  };
};

const getReportingLag = () => {
  return window.PAGE_LOAD_TIMESTAMP ? new Date().getTime() - window.PAGE_LOAD_TIMESTAMP.getTime() : 0;
};

const sendHeartbeatEvent = async (interval: number, reportingLag: number) => {
  const baseCloudEventData = await getBaseCloudEventData();
  const baseData = await getBaseData(reportingLag);
  const event: HeartbeatEvent = {
    ...baseCloudEventData,
    type: EventType.Heartbeat,
    data: {
      ...baseData,
      interval,
    },
  };
  sendEvent(event);
};

const startHeartbeat = () => {
  const reportingLag = getReportingLag();

  // 0 sec
  sendHeartbeatEvent(0, reportingLag);

  // 3 sec
  const delay3s = Math.max(0, 3000 - reportingLag);
  const offset3s = Math.max(0, reportingLag - 3000);
  const timeout3s = setTimeout(() => sendHeartbeatEvent(3, offset3s), delay3s);

  // 10 sec
  const delay10s = Math.max(0, 10000 - reportingLag);
  const offset10s = Math.max(0, reportingLag - 10000);
  const timeout10s = setTimeout(() => sendHeartbeatEvent(10, offset10s), delay10s);

  // 30 sec
  const delay30s = Math.max(0, 30000 - reportingLag);
  const offset30s = Math.max(0, reportingLag - 30000);
  const timeout30s = setTimeout(() => sendHeartbeatEvent(30, offset30s), delay30s);

  // 60 sec intervals
  const interval60s = setInterval(() => sendHeartbeatEvent(60, 0), 60000);

  // Cleanup timers
  return function clearHeartbeat() {
    clearTimeout(timeout3s);
    clearTimeout(timeout10s);
    clearTimeout(timeout30s);
    clearInterval(interval60s);
  };
};

const sendPageViewEvent = async (data: PageViewData) => {
  const reportingLag = getReportingLag();
  const baseCloudEventData = await getBaseCloudEventData();
  const baseData = await getBaseData(reportingLag);
  const event: PageViewEvent = {
    ...baseCloudEventData,
    type: EventType.PageView,
    data: {
      ...baseData,
      ...data,
    },
  };
  sendEvent(event);
};

const sendSessionStartEvent = async (query: NextParsedUrlQuery) => {
  // Do not send this event if there is already a session
  if (sessionStorage.getItem('SESSION_ID')) {
    return;
  }

  const reportingLag = getReportingLag();
  const baseCloudEventData = await getBaseCloudEventData();
  const baseData = await getBaseData(reportingLag);
  const sessionStartEvent: SessionStartEvent = {
    ...baseCloudEventData,
    type: EventType.SessionStart,
    data: {
      ...baseData,
      utm: {
        source: query.utm_source as string,
        medium: query.utm_medium as string,
        campaign: query.utm_campaign as string,
        term: query.utm_term as string,
        content: query.utm_content as string,
        ref: query.utm_ref as string,
      },
    },
  };
  sendEvent(sessionStartEvent);
};

const sendUserActionErrorEvent = async (data: UserActionErrorData) => {
  const baseCloudEventData = await getBaseCloudEventData();
  const baseData = await getBaseData();
  const userActionErrorEvent: UserActionErrorEvent = {
    ...baseCloudEventData,
    type: EventType.UserActionError,
    data: {
      ...baseData,
      ...data,
    },
  };
  sendEvent(userActionErrorEvent);
};

const sendUserActionStartEvent = async (data: UserActionData) => {
  const baseCloudEventData = await getBaseCloudEventData();
  const baseData = await getBaseData();
  const userActionStartEvent: UserActionStartEvent = {
    ...baseCloudEventData,
    type: EventType.UserActionStart,
    data: {
      ...baseData,
      ...data,
    },
  };
  sendEvent(userActionStartEvent);
};

const sendUserActionSucceedEvent = async (data: UserActionData) => {
  const baseCloudEventData = await getBaseCloudEventData();
  const baseData = await getBaseData();
  const userActionSucceedEvent: UserActionSucceedEvent = {
    ...baseCloudEventData,
    type: EventType.UserActionSucceed,
    data: {
      ...baseData,
      ...data,
    },
  };
  sendEvent(userActionSucceedEvent);
};

export const Metrics = {
  startHeartbeat,
  sendPageViewEvent,
  sendSessionStartEvent,
  sendUserActionErrorEvent,
  sendUserActionStartEvent,
  sendUserActionSucceedEvent,
};

import { Text } from '@youversion/design-system-components';
import YouVersionLogo from '@youversion/design-system-icons/lib/YouVersionLogo';
import { Trans, useTranslation } from 'next-i18next';

export function MaintenanceMode() {
  const { t } = useTranslation();

  return (
    <div className='flex items-center justify-center w-screen h-screen'>
      <div className='text-center'>
        <div className='mbe-5'>
          <YouVersionLogo className='h-[18px] w-[117px]' title={t('yv_logo')} />
        </div>
        <Text component='h2' size='md' variant='headline'>
          {t('maintenance.heading')}
        </Text>
        <Text variant='paragraph'>
          <Trans components={{ a: <a href='https://partner-support.youversion.com/' /> }} i18nKey='maintenance.body' />
        </Text>
      </div>
    </div>
  );
}

import * as Sentry from '@sentry/nextjs';
import { useAuth, useInitialize } from '@youversion/auth';
import { useRouter } from 'next/compat/router';
import { useTranslation } from 'next-i18next';
import * as React from 'react';

import { PermissionsProvider } from '@/components/permissions/context';
import { OrganizationProvider } from '@/context/organization';
import { useLocalizedSignInLink } from '@/hooks/locales';
import { MAINTENANCE_MODE, SENTRY_DSN } from '@/utils/env';
import { errorToast } from '@/utils/toast';

import { FullPageLoader } from '../loaders/full-page-loader';
import { MaintenanceMode } from '../maintenance/Maintenance';

const shouldNotRedirectRoutes = Object.freeze([
  '/authenticate',
  '/youversion-content-contribution-agreement',
  '/email/unsubscribe',
]);

interface Props {
  children: React.ReactNode;
}

const maintenanceModeEnabled = MAINTENANCE_MODE === 'on';

/**
 * Checks that the user is authenticated/authorized before rendering the page.
 * If not, redirect to login with route preservation.
 *
 * @example
 * If the app is authenticated, this will enable internal routes, app navigation, and organization context.
 * ```
 * // In `_app.tsx`.
 * <AuthenticatedPage>
 *   <Component {...pageProps} />
 * </AuthenticatedPage>
 * ```
 */
export default function AuthenticatedPage({ children }: Props) {
  const { isError, isLoading, isSignedIn, user } = useAuth();
  const router = useRouter();
  const { t } = useTranslation();

  // If the current page is `/authenticate`, do not initialize.
  // The `/authenticate` page uses the `initializeFromRedirect` handler.
  // If both initializers are run it causes a race condition.
  const isAuthenticatePage = Boolean(router?.pathname === '/authenticate');

  useInitialize({ disabled: isAuthenticatePage });

  const shouldRedirect = Boolean(!isSignedIn && router?.pathname && !shouldNotRedirectRoutes.includes(router.pathname));

  const signInLink = useLocalizedSignInLink();

  React.useEffect(() => {
    if (SENTRY_DSN && user?.id) {
      Sentry.setUser({ id: user.id.toString() });
    }
  }, [user?.id]);

  React.useEffect(() => {
    if (shouldRedirect && !isLoading && !maintenanceModeEnabled) {
      window.location.href = signInLink;
    }
  }, [isLoading, shouldRedirect, router, signInLink]);

  React.useEffect(() => {
    if (isError) {
      errorToast(t('something_went_wrong_retry'));
    }
  }, [isError, t]);

  if (maintenanceModeEnabled) {
    return <MaintenanceMode />;
  }

  if (isAuthenticatePage) {
    return <>{children}</>;
  }

  if (isLoading || shouldRedirect) {
    return <FullPageLoader />;
  }

  if (isSignedIn) {
    return (
      <OrganizationProvider>
        <PermissionsProvider>{children}</PermissionsProvider>
      </OrganizationProvider>
    );
  }

  return <>{children}</>;
}

/**
 * Filters an array of objects distinct by a property value.
 *
 * @param list - The array of objects to filter.
 * @param key - The property name to filter by.
 * @returns The filtered array.
 * @example
 * ```ts
 * filterObjectsDistinctBy(
 *   [
 *     { id: 1, name: 'one' },
 *     { id: 2, name: 'one' },
 *     { id: 3, name: 'three' },
 *     { id: 4, name: 'four' },
 *   ],
 *   'name'
 * );
 *
 * // [
 * //  { id: 1, name: 'one' },
 * //  { id: 3, name: 'three' },
 * //  { id: 4, name: 'four' },
 * // ]
 * ```
 */
export const filterObjectsDistinctBy = <T extends {}>(list: Array<T>, key: keyof T): Array<T> => {
  const filtered = [];
  const seen = new Set();

  for (const item of list) {
    if (seen.has(item[key])) {
      continue;
    }
    seen.add(item[key]);
    filtered.push(item);
  }

  return filtered;
};

import commonLocaleStrings from '../../locale-strings/common.json';

export const logoLanguageSelectOptions: Array<{ key: string; label?: string }> = [
  { key: 'en' },
  { key: 'es' },
  { key: 'fr' },
  { key: 'pt' },
  { key: 'tl' },
  { key: 'af' },
  { key: 'de' },
  { key: 'id' },
  { key: 'it' },
  { key: 'ja' },
  { key: 'ko' },
  { key: 'nl' },
  { key: 'pl' },
  { key: 'ro' },
  { key: 'ru' },
  { key: 'vi' },
  { key: 'zh-CN' },
  { key: 'zh-TW' },
  { key: 'az' },
  { key: 'be' },
  { key: 'bg' },
  { key: 'bn' },
  { key: 'ca' },
  { key: 'cs' },
  { key: 'cy' },
  { key: 'da' },
  { key: 'el' },
  { key: 'et' },
  { key: 'eu' },
  { key: 'fi' },
  { key: 'gl' },
  { key: 'gu-IN' },
  { key: 'hi' },
  { key: 'hr' },
  { key: 'ht' },
  { key: 'hu' },
  { key: 'hy-AM' },
  { key: 'is' },
  { key: 'ka' },
  { key: 'kk' },
  { key: 'km' },
  { key: 'kn' },
  { key: 'ky' },
  { key: 'lt' },
  { key: 'lv' },
  { key: 'mk' },
  { key: 'ml-IN' },
  { key: 'mn' },
  { key: 'mr' },
  { key: 'ms' },
  { key: 'my' },
  { key: 'ne-NP' },
  { key: 'no' },
  { key: 'pa' },
  { key: 'pt-PT' },
  { key: 'rm-CH' },
  { key: 'sl-LK' },
  { key: 'sk' },
  { key: 'sl' },
  { key: 'sn' },
  { key: 'sq' },
  { key: 'sr-CS' },
  { key: 'sv-SE' },
  { key: 'sw' },
  { key: 'ta' },
  { key: 'te' },
  { key: 'th' },
  { key: 'tr' },
  { key: 'uk' },
  { key: 'uz' },
  { key: 'yo' },
  { key: 'zu' },
  { key: 'ar' },
  { key: 'ckb' },
  { key: 'fa' },
  { key: 'he' },
  { key: 'ur-PK' },
];

/**
 * NOTE: Codes higher in the list have priority when performing a conversion from a short code.
 * Because of this, most common language variations should be higher in the list.
 * EX: convertShortCodeFromLong('en') will return 'en-US' because it is higher in this list.
 */
export enum LocaleCode {
  /* eslint-disable typescript-sort-keys/string-enum */
  Afrikaans = 'af-ZA',
  AlbanianAlbania = 'sq-AL',
  Amharic = 'am-ET',
  ArabicPakistan = 'pa-PK',
  ArabicSaudiArabia = 'ar-SA',
  Belarusian = 'be-BY',
  BengaliBangladesh = 'bn-BD',
  Bulgarian = 'bg-BG',
  Burmese = 'my-QZ',
  BurmeseMyanmar = 'my-MM',
  Catalan = 'ca-ES',
  ChineseHongKong = 'zh-HK',
  ChineseSimplified = 'zh-CN',
  ChineseTraditional = 'zh-TW',
  CroatianCroatia = 'hr-HR',
  Czech = 'cs-CZ',
  Danish = 'da-DK',
  Dutch = 'nl-NL',
  EnglishUs = 'en-US',
  EnglishUk = 'en-GB',
  Estonian = 'et-EE',
  Farsi = 'fa-IR',
  Filipino = 'fil-PH',
  Finnish = 'fi-FI',
  French = 'fr-FR',
  GeorgianGeorgia = 'ka-GE',
  GermanGermany = 'de-DE',
  GreekGreece = 'el-GR',
  Gujarati = 'gu-IN',
  HaitianCreole = 'ht-HT',
  HebrewIsrael = 'he-IL',
  HindiIndia = 'hi-IN',
  HungarianHungary = 'hu-HU',
  IcelandicIceland = 'is-IS',
  IgboNigeria = 'ig-NG',
  Indonesian = 'id-ID',
  ItalianItaly = 'it-IT',
  JapaneseJapan = 'ja-JP',
  KannadaIndia = 'kn-IN',
  KhmerCambodia = 'km-KH',
  KoreanKorea = 'ko-KR',
  Kurdish = 'ku',
  LatvianLatvia = 'lv-LV',
  LithuanianLithuania = 'lt-LT',
  Macedonian = 'mk-MK',
  Malay = 'ms-MY',
  MalayalamIndia = 'ml-IN',
  Mongolian = 'mn-MN',
  Nepali = 'ne-IN',
  Norwegian = 'no-NO',
  Polish = 'pl-PL',
  PortugueseBrazil = 'pt-BR',
  PortuguesePortugal = 'pt-PT',
  RomanianRomania = 'ro-RO',
  RussianRussia = 'ru-RU',
  Serbian = 'sr-CS',
  SerbianLatin = 'sr-SP',
  ShonaZimbabwe = 'sn-ZW',
  SinhalaSriLanka = 'si-LK',
  SlovakSlovakia = 'sk-SK',
  SlovenianSlovenia = 'sl-SI',
  SpanishLatinAmerica = 'es-LA',
  SpanishSpain = 'es-ES',
  SwahiliKenya = 'sw-KE',
  SwedishSweden = 'sv-SE',
  TamilIndia = 'ta-IN',
  TelugaIndia = 'te-IN',
  Thai = 'th-TH',
  Turkish = 'tr-TR',
  Ukrainian = 'uk-UA',
  Urdu = 'ur-PK',
  Vietnamese = 'vi-VN',
  WelshUk = 'cy-GB',
  YorubaNigeria = 'yo-NG',
  ZuluSouthAfrica = 'zu-ZA',
}

type LanguageKey = keyof typeof commonLocaleStrings.languages;
/**
 * Some language display strings are not included in node_modules/cldr-data/main/en/languages.json. For these cases this
 * map can be used to map the Locale to a key in the i18next common file.
 */
export const LocaleCodeToLanguageTranslationKey = new Map<LocaleCode, LanguageKey>([
  [LocaleCode.ChineseTraditional, 'chinese-traditional'],
  [LocaleCode.ChineseSimplified, 'chinese-simplified'],
  [LocaleCode.ChineseHongKong, 'chinese-hong-kong'],
  [LocaleCode.BurmeseMyanmar, 'myanmar-burmese'],
]);

export const enabledRootLocales = [
  LocaleCode.Afrikaans,
  LocaleCode.AlbanianAlbania,
  LocaleCode.ArabicSaudiArabia,
  LocaleCode.Belarusian,
  LocaleCode.Bulgarian,
  LocaleCode.Catalan,
  LocaleCode.ChineseHongKong,
  LocaleCode.ChineseSimplified,
  LocaleCode.ChineseTraditional,
  LocaleCode.CroatianCroatia,
  LocaleCode.Czech,
  LocaleCode.Dutch,
  LocaleCode.EnglishUk,
  LocaleCode.EnglishUs,
  LocaleCode.Farsi,
  LocaleCode.Filipino,
  LocaleCode.Finnish,
  LocaleCode.French,
  LocaleCode.GermanGermany,
  LocaleCode.HaitianCreole,
  LocaleCode.HindiIndia,
  LocaleCode.HungarianHungary,
  LocaleCode.IcelandicIceland,
  LocaleCode.Indonesian,
  LocaleCode.ItalianItaly,
  LocaleCode.JapaneseJapan,
  LocaleCode.KhmerCambodia,
  LocaleCode.KoreanKorea,
  LocaleCode.LatvianLatvia,
  LocaleCode.LithuanianLithuania,
  LocaleCode.Malay,
  LocaleCode.Mongolian,
  LocaleCode.Norwegian,
  LocaleCode.Polish,
  LocaleCode.PortugueseBrazil,
  LocaleCode.PortuguesePortugal,
  LocaleCode.RomanianRomania,
  LocaleCode.RussianRussia,
  LocaleCode.SlovakSlovakia,
  LocaleCode.SlovenianSlovenia,
  LocaleCode.SpanishLatinAmerica,
  LocaleCode.SpanishSpain,
  LocaleCode.SwahiliKenya,
  LocaleCode.SwedishSweden,
  LocaleCode.TamilIndia,
  LocaleCode.Thai,
  LocaleCode.Turkish,
  LocaleCode.Ukrainian,
  LocaleCode.Vietnamese,
  LocaleCode.WelshUk,
  LocaleCode.ZuluSouthAfrica,
];

export const allLocaleCodes = Object.values(LocaleCode);

import { LanguageType } from '@/hooks/locales';

import { getDayJSLanguageTags } from './get-dayjs-language-tags';

export const getDayJSLanguageTagFromLocale = (allLanguages: Array<LanguageType>, locale?: string): string => {
  const allLangTags = getDayJSLanguageTags(allLanguages);

  const localeLangYVTag = allLanguages
    .find(lang => lang.locale === locale)
    ?.apiTag.toLowerCase()
    .split('_')
    .join('-');
  const localeLangDayJSTag = allLangTags.find(langTag => langTag.langTagYV === localeLangYVTag)?.langTagDayJS || 'en';

  return localeLangDayJSTag;
};

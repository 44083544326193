import { Toast } from '@youversion/design-system-components';
import { ToastProps } from '@youversion/design-system-components/dist/src/Toast/Toast';
import toast from 'react-hot-toast';

const defaultOptions: ToastProps = {
  position: 'inlineEnd',
};

export function successToast(message: string) {
  const toastId = toast.custom(
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Toast dismiss={() => toast.remove(toastId)} duration={2000} variant='success' {...defaultOptions}>
      {message}
    </Toast>
  );

  return toastId;
}

export function infoToast(message: string) {
  const toastId = toast.custom(
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Toast dismiss={() => toast.remove(toastId)} duration={5000} variant='info' {...defaultOptions}>
      {message}
    </Toast>
  );

  return toastId;
}

export function errorToast(message: string) {
  const toastId = toast.custom(
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Toast dismiss={() => toast.remove(toastId)} duration={5000} variant='error' {...defaultOptions}>
      {message}
    </Toast>
  );

  return toastId;
}

import { DatesProvider } from '@mantine/dates';
import { useAuth } from '@youversion/auth';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/compat/router';
import { i18n } from 'next-i18next';
import * as React from 'react';
import { useEffect, useMemo } from 'react';

import { useDefaultBrowserLocale } from '@/hooks/locales';
import dayjs from '@/lib/dayjs';
import { getEnabledLocales } from '@/utils/locale-utils';
import { getValidDayJSLocaleKey } from '@/utils/localization/get-valid-day-js-locale-key';

interface AppLanguageContext {
  appLanguage: string;
}

const AppLanguageContext = React.createContext<AppLanguageContext>({ appLanguage: 'en-US' });

interface Props {
  acceptLanguage?: string;
  children: React.ReactNode;
}

export const AppLanguageProvider: React.FC<Props> = ({ children, acceptLanguage }) => {
  const app_locale = getCookie('app_locale');
  const { browserLanguage } = useDefaultBrowserLocale(acceptLanguage);
  // @ts-expect-error: Property 'language' does not exist on type 'i18n'
  const currentLanguage = i18n?.language;
  const enabledLocales = getEnabledLocales();
  const router = useRouter();
  const { user } = useAuth();

  const [currentAppLanguage, setCurrentAppLanguage] = React.useState(currentLanguage);

  useEffect(() => {
    // Check router
    if (router?.locale) {
      setCurrentAppLanguage(router.locale);
    }
    // Check cookies
    else if (app_locale) {
      setCurrentAppLanguage(app_locale);
    }
    // Check browser default language settings. 1st navigator.language then Accept-Language
    else if (browserLanguage) {
      setCurrentAppLanguage(browserLanguage);
    }
    // Check user object
    else if (user?.languageTag) {
      if (enabledLocales.includes(user.languageTag)) {
        setCurrentAppLanguage(user.languageTag);
      } else {
        const rootLocale = new Intl.Locale(user.languageTag);
        const userLang = enabledLocales.find(locale => locale.includes(rootLocale.language!)) || 'en-US';
        setCurrentAppLanguage(userLang);
      }
    } else {
      // fallback to en-US
      setCurrentAppLanguage(currentLanguage || 'en-US');
    }
  }, [app_locale, browserLanguage, currentLanguage, enabledLocales, router?.locale, user?.languageTag]);

  // Initiate localized dayjs
  const localeLangDayJSTag = useMemo(() => getValidDayJSLocaleKey(currentAppLanguage ?? 'en-US'), [currentAppLanguage]);
  useEffect(() => {
    if (localeLangDayJSTag !== dayjs.locale()) {
      import(`dayjs/locale/${localeLangDayJSTag}.js`).then(
        () => dayjs.locale(localeLangDayJSTag),
        () => {
          // in case the import fails
          dayjs.locale('en');
        }
      );
    }
  }, [localeLangDayJSTag]);

  if (currentLanguage && currentLanguage !== currentAppLanguage) {
    deleteCookie('app_locale', { path: '/' });
    setCookie('app_locale', currentAppLanguage, { path: '/' });
    router?.replace(
      { pathname: router.pathname, query: router.query },
      {},
      {
        locale: currentAppLanguage,
      }
    );
  }

  return (
    <AppLanguageContext.Provider value={{ appLanguage: currentAppLanguage }}>
      <DatesProvider settings={{ locale: localeLangDayJSTag }}>{children}</DatesProvider>
    </AppLanguageContext.Provider>
  );
};

/**
 * Hook to be used on page components that returns the default language for the applicaiton.
 *
 * @example
 * ```ts
 * const { appLanguage } = useAppLanguage();
 *
 * const defaultLocale = appLanguage;
 * ```
 */

export const useAppLanguage = () => {
  const context = React.useContext(AppLanguageContext);

  if (!context) {
    throw new Error('`useLanguage` must be used inside of an `AppLanguageProvider`.');
  }

  return context;
};

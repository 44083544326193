type MaintenanceModeType = 'on' | 'off';

type APIEnvs = 'development' | 'staging' | 'production';
export const API_ENV = (process.env.NEXT_PUBLIC_API_ENV as APIEnvs) || 'staging';
export const APP_VERSION: string = process.env.APP_VERSION || 'local-dev'; // set in nextjs config.
export const CUBE_CLOUD_API_URL: string = process.env.NEXT_PUBLIC_CUBE_CLOUD_API_URL || 'fake-url';
export const CUBE_CLOUD_API_GRAPHQL_URL: string = process.env.NEXT_PUBLIC_CUBE_CLOUD_API_GRAPHQL_URL || 'fake-url';
export const GOOGLE_MAPS_API_KEY: string = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || 'fake-key';
export const IS_STAGING: boolean = Boolean(process.env.NEXT_PUBLIC_IS_PRODUCTION !== 'true');
export const MAINTENANCE_MODE: MaintenanceModeType =
  (process.env.NEXT_PUBLIC_MAINTENANCE_MODE as MaintenanceModeType) || 'off';
export const SENTRY_DSN: string | undefined = process.env.NEXT_PUBLIC_SENTRY_DSN;
export const YV_AUTH_CLIENT_ID: string = process.env.NEXT_PUBLIC_YV_AUTH_CLIENT_ID || 'fake-id';
export const YV_AUTH_CLIENT_SECRET: string = process.env.NEXT_PUBLIC_YV_AUTH_CLIENT_SECRET || 'fake-secret';
export const COMMIT_SHA: string = process.env.NEXT_PUBLIC_COMMIT_SHA ?? 'unknown';
export const DATAMAN_URL: string =
  process.env.NEXT_PUBLIC_DATAMAN_URL ?? 'https://dataman.youversionapistaging.com/4.0/events';

import { LocaleHeader } from '@/graphql';

import { LocaleCode } from '../constants';

interface Props {
  locale?: keyof typeof LocaleCode | string;
  localeHeaders: Array<LocaleHeader>;
  shouldFallBack?: boolean;
}

/**
 * This function finds the API LocaleHeader equivalent of the current app locale.
 *
 * @param locale - An override {@link LocaleCode} to convert to a LocaleHeader.
 * @param localeHeaders - An array of {@link LocaleHeader} returned from the API.
 * @param shouldFallBack - If the locale should fallback to the app locale or `en-US`. (Defaults to true).
 * @returns
 * - A {@link LocaleHeader}.
 * @example
 * ```typescriptreact
 * import { useLocales } from '@/hooks/useLocales';
 *
 * const ExampleComponent = () => {
 *   const { convertAppLocaleToLocaleHeader } = useLocales();
 *   const { i18n } = useTranslation();
 *   const { data: apiLocaleHeaders } = useGetLocaleHeadersQuery()
 *
 *  // Converts app language into API LocaleHeader.
 *   const currentAppLanguageTag = convertAppLocaleToLocaleHeader(i18n.language).apiTag
 *  ...
 *  useInfiniteSearchPlansQuery({languageTag: currentAppLanguageTag})
 * }
 * ```
 */
export const convertAppLocaleToLocaleHeader = ({
  localeHeaders,
  locale,
  shouldFallBack,
}: Props): LocaleHeader | undefined => {
  const fallback = shouldFallBack === undefined ? true : shouldFallBack;
  // @ts-expect-error: Property 'language' does not exist on type 'i18n'
  const appLocale = fallback ? locale || i18n.language || 'en-US' : locale;

  // Check LocaleHeader.locale
  const appLocaleHeader = localeHeaders.find(localeHeader => localeHeader.locale === appLocale);

  // Fallback to the rootLanguage of appLocale
  const rootLocale = new Intl.Locale(appLocale);
  const rootLocaleHeader = localeHeaders.find(localeHeader =>
    localeHeader.locale?.includes(rootLocale.language as string)
  );

  return appLocaleHeader || rootLocaleHeader || undefined;
};

import { useAuth } from '@youversion/auth';
import { useRouter } from 'next/compat/router';

import { useCurrentOrganizationId } from '@/context/organization/useCurrentOrganizationId';
import { useGetAdminOrganizationsQuery } from '@/graphql';
import notEmpty from '@/utils/notEmpty';

type Params = {
  enabled?: boolean;
};

/**
 * A hook that fetches the organizations that the current authenticated user is a member of.
 *
 * @returns
 * - isError -  If the query attempt resulted in an error.
 * - isLoading - If the query is currently fetching.
 * - organizations - The organizations that the current authenticated user is a member of.
 *
 * @example
 * ```typescript
 * import { useOrganizationsForUser } from '@/context/organization';
 *
 * function MyComponent() {
 *   const { organizations, isError, isLoading } = useOrganizationsForUser();
 *   // return...
 * }
 * ```
 */
export const useOrganizationsForUser = ({ enabled }: Params = { enabled: true }) => {
  const router = useRouter();
  const { user, isSignedIn } = useAuth();
  const { organizationId } = useCurrentOrganizationId();

  const {
    isLoading,
    isError,
    data: organizations,
  } = useGetAdminOrganizationsQuery(
    // Optional chain in here is ok, because `user` is a condition of `enabled` for the query.
    { userId: user?.id || 0 },
    {
      enabled: Boolean(enabled && isSignedIn && user?.id),
      onSuccess: async () => {
        if (!organizationId && router?.pathname !== '/organization-select') {
          await router?.push('/organization-select');
        }
      },
      select: data => data.getAdminOrganizations?.data?.filter(notEmpty) || [],
    }
  );

  return {
    isError,
    isLoading: Boolean(isLoading && isSignedIn),
    organizations,
  };
};

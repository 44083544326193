import { useRouter } from 'next/router';
import { useCallback } from 'react';

/**
 * Non-destructively updates url query params. Similar to `PATCH` for API methods.
 *
 * @example
 * ```tsx
 * import { useQueryParams, useUpdateQueryParams } from '@/hooks';
 *
 * function MyComponent() {
 *   const updateQueryParams = useUpdateQueryParams();
 *   const { name } = useQueryParams();
 *
 *   function handleChangeName(event) {
 *     updateQueryParams({ name: event.target.value });
 *   }
 *
 *   return (
 *     <input value={name} onChange={handleChangeName} />
 *   )
 * }
 * ```
 */
export function useUpdateQueryParams() {
  const router = useRouter();

  const updateQueryParams = useCallback((params: Record<string, string>) => {
    const queryParams = router.query;
    delete queryParams.slug;
    router.replace(
      {
        query: { ...queryParams, ...params },
      },
      undefined,
      { shallow: true }
    );
    // NextJS Router is not memoized and causes infinite renders as a dependency.
    // It's fixed in the new `app` router with NextJS 13.4+, so this will be resolved when migrating to that.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return updateQueryParams;
}

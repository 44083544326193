import { useAuth } from '@youversion/auth';
import * as React from 'react';

import { useOrganization } from '@/context/organization/useOrganization';
import { useGetExpandBatchQuery } from '@/graphql';
import { PermissionKey, ProfileType } from '@/utils/constants';
import notEmpty from '@/utils/notEmpty';

import useAllResourceIds from '../../useAllResourceIds';

export type UserPermission = {
  permissions: Array<PermissionKey>;
  resource: string | null | undefined;
};

interface PermissionsContextParams {
  isError: boolean;
  isLoading: boolean;
  organizationProfiles: Array<ProfileType>;
  userPermissions: Array<UserPermission>;
}

export const PermissionsContext = React.createContext<PermissionsContextParams>({
  isError: false,
  isLoading: true,
  organizationProfiles: [],
  userPermissions: new Array<UserPermission>(),
});

interface Props {
  children: React.ReactNode;
}

export const PermissionsProvider = ({ children }: Props) => {
  const {
    addressProfile,
    bibleProfile,
    churchProfile,
    contentProfile,
    isError: organizationIsError,
    isLoading: isOrganizationLoading,
  } = useOrganization();
  const { user } = useAuth();
  const allResourceIds = useAllResourceIds();
  const isLocationListLoading = Boolean(allResourceIds.length <= 0);

  const {
    data: userPermissions,
    isError: getExpandBatchIsError,
    isLoading: isPermissionListQueryLoading,
  } = useGetExpandBatchQuery(
    // Optional chain in the string here is ok, because `user` is a condition of `enabled` for the query.
    { principleId: `user:${user?.id}`, resources: allResourceIds },
    {
      enabled: Boolean(allResourceIds.length && user?.id),
      select: data => {
        const resourcePermissionsArray: Array<UserPermission> =
          data?.getExpandBatch?.data?.map(resourcePermission => {
            return {
              permissions: (resourcePermission?.permissions?.filter(notEmpty) as Array<PermissionKey>) || [],
              resource: resourcePermission?.resource,
            };
          }) || [];
        return resourcePermissionsArray;
      },
    }
  );
  const isPermissionListLoading = Boolean(isPermissionListQueryLoading && allResourceIds.length);

  const isLoading = Boolean(isOrganizationLoading || isLocationListLoading || isPermissionListLoading);

  const organizationProfiles = React.useMemo(() => {
    let profiles: Array<ProfileType> = [];
    if (addressProfile !== undefined) {
      profiles.push('address');
    }
    if (bibleProfile !== undefined) {
      profiles.push('bible');
    }
    if (churchProfile !== undefined) {
      profiles.push('church');
    }
    if (contentProfile !== undefined) {
      profiles.push('content');
    }
    return profiles;
  }, [addressProfile, bibleProfile, churchProfile, contentProfile]);

  return (
    <PermissionsContext.Provider
      value={{
        isError: getExpandBatchIsError || organizationIsError,
        isLoading,
        organizationProfiles,
        userPermissions: userPermissions || [],
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => React.useContext(PermissionsContext);

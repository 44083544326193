import 'nprogress/nprogress.css';
import '../styles/globals.css';

import type { DehydratedState } from '@tanstack/react-query';
import { Hydrate, hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AuthProvider } from '@youversion/auth';
import { Dataman, generatePageIds } from '@youversion/dataman';
import { DatamanProvider } from '@youversion/dataman-react';
import { getCookies } from 'cookies-next';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import nextRouter, { useRouter } from 'next/router';
import { appWithTranslation, i18n } from 'next-i18next';
import { ThemeProvider as NextThemeProvider } from 'next-themes';
import nProgress from 'nprogress';
import * as React from 'react';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';

import { Metrics } from '@/api/metrics';
import AuthenticatedPage from '@/components/auth/AuthenticatedPage';
import { AppLanguageProvider } from '@/context/appLanguage';
import MantineProvider from '@/context/mantine/MantineProvider';
import {
  useGetLocaleHeadersQuery,
  useGetLocationsBaseDataQuery,
  useGetOrganizationQuery,
  useGetOrganizationServiceTimesQuery,
} from '@/graphql';
import { API_ENV, COMMIT_SHA, DATAMAN_URL, IS_STAGING, YV_AUTH_CLIENT_ID, YV_AUTH_CLIENT_SECRET } from '@/utils/env';
import { generateServerSidePrefetcherQuery, getSSRHeaders, serverSidePrefetcher } from '@/utils/server-side';

declare global {
  interface Window {
    PAGE_LOAD_TIMESTAMP: Date;
  }
}

nProgress.configure({
  showSpinner: false,
});
interface NextAppProps extends Pick<AppProps, 'pageProps' | 'Component'> {
  acceptLanguage?: string;
  dehydratedState?: DehydratedState;
  initialDehydratedState?: DehydratedState;
}

const MyApp = ({ Component, pageProps, acceptLanguage, initialDehydratedState }: NextAppProps) => {
  const router = useRouter();
  const { dehydratedState } = pageProps as NextAppProps;
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            cacheTime: 1000 * 60, // 60s
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            staleTime: 1000 * 10, // 10s
          },
        },
      })
  );

  const [datamanClient] = React.useState(
    () =>
      new Dataman({
        applicationId: 'com.youversion.connect',
        appVersion: COMMIT_SHA,
        baseDomain: 'youversion.com',
        clientId: YV_AUTH_CLIENT_ID,
        debug: false,
        // @ts-ignore
        endpoint: DATAMAN_URL,
        environment: API_ENV,
      })
  );

  const datamanEvent = React.useMemo(() => datamanClient.buildEvent(router.pathname), [datamanClient, router.pathname]);

  useEffect(() => {
    datamanClient.logImpression({
      contentIds: generatePageIds({ contentType: 'page', pathname: router.pathname }),
      elementId: router.pathname,
      event: datamanEvent,
      pathname: router.pathname,
    });
  }, [datamanClient, datamanEvent, router.pathname]);

  queryClient.setQueryDefaults(useGetLocaleHeadersQuery.getKey(), { cacheTime: Infinity, staleTime: Infinity });
  if (initialDehydratedState) {
    hydrate(queryClient, initialDehydratedState);
  }

  // @ts-expect-error: Property 'dir' does not exist on type 'i18n'
  const appDirection = i18n?.dir() || 'ltr';

  useEffect(() => {
    window.PAGE_LOAD_TIMESTAMP = new Date();
    if (router) {
      Metrics.sendSessionStartEvent(router.query);
    }
    const clearHeartbeat = Metrics.startHeartbeat();

    return () => clearHeartbeat();
    // This effect should only run once even if router.query changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // This effect changes the direction of the app, depending on the i18n.dir value
    if (appDirection) {
      document.querySelector('html')?.setAttribute('dir', appDirection);
    }
  }, [appDirection]);

  useEffect(() => {
    window.PAGE_LOAD_TIMESTAMP = new Date();
    if (router) {
      Metrics.sendPageViewEvent({
        name: router.pathname,
        path: router.asPath,
        referrer: document.referrer,
        title: document.title,
        url: window.location.href,
      });
    }
  }, [router?.pathname, router?.asPath, router]);

  return (
    <DatamanProvider value={datamanClient}>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={dehydratedState}>
          <AuthProvider
            config={{
              clientId: YV_AUTH_CLIENT_ID,
              clientSecret: YV_AUTH_CLIENT_SECRET,
              isStaging: IS_STAGING,
            }}
          >
            <NextThemeProvider>
              <MantineProvider>
                <AppLanguageProvider acceptLanguage={acceptLanguage}>
                  <Toaster position='bottom-right' toastOptions={{ duration: Infinity }} />

                  <AuthenticatedPage>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <Component {...pageProps} />
                  </AuthenticatedPage>
                </AppLanguageProvider>
              </MantineProvider>
            </NextThemeProvider>
          </AuthProvider>
        </Hydrate>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </DatamanProvider>
  );
};

nextRouter.events.on('routeChangeStart', () => nProgress.start());
nextRouter.events.on('routeChangeError', () => nProgress.done());
nextRouter.events.on('routeChangeComplete', () => nProgress.done());

MyApp.getInitialProps = async (appContext: AppContext) => {
  const acceptLanguage = appContext.ctx.req?.headers['accept-language'];
  const appProps = await App.getInitialProps(appContext);

  const { organization_id = '', ssrYva } = getCookies({
    req: appContext.ctx.req,
    res: appContext.ctx.res,
  });

  const orgIdQuery = appContext.router.query?.organization_id;

  const organizationId = orgIdQuery && typeof orgIdQuery === 'string' ? orgIdQuery : organization_id;

  const pathname = appContext.ctx.pathname;

  const NON_ORGANIZATION_ROUTES = ['/authenticate'];
  const routeRequiresOrganizationData = !Boolean(NON_ORGANIZATION_ROUTES.includes(pathname));

  const requestHeaders = getSSRHeaders(appContext.ctx);
  const dehydratedState = await serverSidePrefetcher(
    [
      generateServerSidePrefetcherQuery(useGetOrganizationQuery, requestHeaders, { organizationId }),
      generateServerSidePrefetcherQuery(useGetLocaleHeadersQuery, requestHeaders, {}),
      generateServerSidePrefetcherQuery(useGetOrganizationServiceTimesQuery, requestHeaders, { organizationId }),
      generateServerSidePrefetcherQuery(useGetLocationsBaseDataQuery, requestHeaders, {
        parentOrganizationId: organizationId,
      }),
    ],
    { enabled: Boolean(ssrYva && organizationId && routeRequiresOrganizationData) }
  );

  return {
    ...appProps,
    acceptLanguage,
    initialDehydratedState: dehydratedState,
  };
};

export default appWithTranslation(MyApp);

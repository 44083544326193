import { RoleBinding } from '@/graphql';

/** Global-level account role IDs.
 *
 * Order of keys is the preferred display order for the UI.
 *
 * @example
 * ```ts
 * import { t } from 'next-i18next';
 * import { accountRoles } from '@/utils/constants';
 *
 * function MyComponent() {
 *   return (
 *     <>
 *       {Object.values(accountRoles).map(role => t(`accounts:roles[${role.name}]`))}
 *     </>
 *   );
 * }
 * ```
 */

export const accountRoles = {
  editor: 'Editor',
  insightsViewer: 'Insights Viewer',
  organizationViewer: 'Organization Viewer',
  owner: 'Owner',
  staffRepresentative: 'Staff Representative',
} as const;

export type AccountRoles = typeof accountRoles;

/** Only certain roles are selectable by the user. */
export type SelectableAccountRoles = Omit<AccountRoles, 'organizationViewer'>;
export type SelectableAccountRole = keyof SelectableAccountRoles;

/**
 * A utility type that extends {@link RoleBinding}, to be used when filtering role bindings and asserting that
 * `binding.role.name` is one of the keys of {@link SelectableAccountRole}.
 */
export type FilteredRoleBinding = RoleBinding & {
  role: RoleBinding['role'] & {
    name: SelectableAccountRole;
  };
};

/**
 * A utility type that extends {@link FilteredRoleBinding} that adds a displayValue string type on the binding.
 */

export type SelectableRoleOption = { fqrn: string; roleKey: SelectableAccountRole };
export type SelectableRoleOptions = Array<SelectableRoleOption>;

export interface DisplayableRole extends SelectableRoleOption {
  displayName: string;
  roleId: string;
}
export type DisplayableRoles = Array<DisplayableRole>;

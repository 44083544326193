import cldrData from 'cldr-data/availableLocales.json';
import { useTranslation } from 'next-i18next';
import * as React from 'react';

import { cldrLanguageCodeMappings, cldrToCubeJsMap, languageSpecificCountryNameOverrides } from '@/utils/cldrHelpers';
import { SelectOption } from '@/utils/types';

/**
 * Used to retrieve localized country names for the language currently set in the app.
 *
 * @returns An `Array<SelectOption>` of localized country names.
 *
 * @example
 * ```typescriptreact
 * import { useLocalizedCountries } from '@/hooks/useLocalizedCountries';
 *
 * const countryList = useLocalizedCountries();
 * ```
 */
export const useLocalizedCountries = (): Array<SelectOption> => {
  const { i18n } = useTranslation();
  const [localizedCountries, setLocalizedCountries] = React.useState<Record<string, string>>({});

  const { currentLanguage } = React.useMemo(
    () => ({
      // @ts-expect-error: Property 'language' does not exist on type 'i18n'
      currentLanguage: i18n.language,
    }),
    [i18n]
  );

  /**
   * Used to retrieve localized country names for the specified language.
   *
   * @param languageCode - Language Code to retrieve localized country names for.
   *
   * @example
   * This is used to override the values for Hong Kong (HK) and Macao (MO) to their alt-short values only for `en` (english)
   * ```typescriptreact
   * await getLocalizedCountryNames('en')
   * ```
   */
  const getLocalizedCountryNames = React.useCallback(async (languageCode: string) => {
    /**
     * Checks to see if any overrides have been set for that language. If there are, it will check to ensure
     * that was a valid key in the first place and if so, override the default with the new value.
     */
    const overrideLanguageSpecificCountryNames = () => {
      const perLanguageCountryNameOverrides = languageSpecificCountryNameOverrides[languageCode];
      if (perLanguageCountryNameOverrides !== undefined) {
        Object.entries(perLanguageCountryNameOverrides).forEach(({ '0': key, '1': value }) => {
          if (cldrCountryNames[key] !== undefined) {
            cldrCountryNames[key] = cldrCountryNames[value];
          }
        });
      }
    };

    const data = await import(`cldr-data/main/${languageCode}/territories.json`);
    const cldrCountryNames: Record<string, string> = data.main[languageCode].localeDisplayNames.territories;

    overrideLanguageSpecificCountryNames();

    const mappedCubeJsCountryList = Object.fromEntries(
      Object.entries(cldrCountryNames).filter(country => Object.keys(cldrToCubeJsMap).includes(country[0]))
    );

    return setLocalizedCountries(mappedCubeJsCountryList);
  }, []);

  React.useEffect(() => {
    const rootLanguage = new Intl.Locale(currentLanguage).language;
    (async () => {
      if (cldrLanguageCodeMappings.find(language => language.languageToOverride === currentLanguage)) {
        await getLocalizedCountryNames(
          cldrLanguageCodeMappings.find(language => language.languageToOverride === currentLanguage)
            ?.languageValuesToUse!
        );
      } else if (cldrData.availableLocales.includes(currentLanguage)) {
        await getLocalizedCountryNames(currentLanguage);
      } else if (rootLanguage && cldrData.availableLocales.includes(rootLanguage)) {
        await getLocalizedCountryNames(rootLanguage);
      } else {
        await getLocalizedCountryNames('en');
      }
    })();
  }, [currentLanguage, getLocalizedCountryNames]);

  return React.useMemo(() => {
    return Object.keys(localizedCountries)
      .map<SelectOption>(country => ({
        label: localizedCountries[country],
        value: country,
      }))
      .sort((a, b) => a.label?.localeCompare(b.label));
  }, [localizedCountries]);
};

import { MantineProvider as DefaultMantineProvider } from '@mantine/core';
import { useTheme } from 'next-themes';
import { ReactNode, useMemo } from 'react';

type Props = {
  children: ReactNode;
};

export default function MantineProvider({ children }: Props) {
  const { resolvedTheme } = useTheme();

  const colorScheme = useMemo(() => {
    if (resolvedTheme === 'light' || resolvedTheme === 'dark') {
      return resolvedTheme;
    }
  }, [resolvedTheme]);

  return <DefaultMantineProvider forceColorScheme={colorScheme}>{children}</DefaultMantineProvider>;
}

import * as Sentry from '@sentry/nextjs';
import { getToken } from '@youversion/auth';
import { isInBrowser } from '@youversion/utils';
import { GraphQLError } from 'graphql';
import { GraphQLErrorArgs } from 'graphql/error/GraphQLError';
import { RequestInit } from 'graphql-request/dist/types.dom';

import { APP_VERSION, IS_STAGING, SENTRY_DSN, YV_AUTH_CLIENT_ID, YV_AUTH_CLIENT_SECRET } from '@/utils/env';

export async function getDefaultFetchHeaders() {
  const token = await getToken({
    clientId: YV_AUTH_CLIENT_ID,
    clientSecret: YV_AUTH_CLIENT_SECRET,
    isStaging: IS_STAGING,
  });

  // Update server-side token
  if (token?.token && token.tokenChanged) {
    fetch('/api/cookies', {
      body: JSON.stringify({ name: 'ssrYva', value: token.token }),
      headers: {
        'content-type': 'application/json',
      },
      method: 'POST',
    });
  }

  const headers: Record<string, string> = {
    'content-type': 'application/json',
    'X-YouVersion-App-Platform': 'web',
    'X-YouVersion-App-Version': APP_VERSION,
    'X-YouVersion-Client': 'youversion-connect',
  };

  if (token?.token) {
    headers.Authorization = `Bearer ${token.token}`;
  }
  return headers;
}

export function fetcher<TData, TVariables>(query: string, variables?: TVariables, options?: RequestInit['headers']) {
  return baseFetcher<TData, TVariables>('v1', query, variables, options);
}

export function fetcherV2<TData, TVariables>(query: string, variables?: TVariables, options?: RequestInit['headers']) {
  return baseFetcher<TData, TVariables>('v2', query, variables, options);
}

export function baseFetcher<TData, TVariables>(
  endpoint: 'v1' | 'v2',
  query: string,
  variables?: TVariables,
  options?: RequestInit['headers']
) {
  return async (): Promise<TData & { error: boolean }> => {
    const isBrowserRequest = isInBrowser();
    const GRAPHQL_ENDPOINT = `https://presentation.youversionapi${IS_STAGING ? 'staging' : ''}.com${
      endpoint.includes('v2') ? '/2.0/graphql' : '/graphql'
    }`;
    const defaultFetchOptions: globalThis.RequestInit = {
      body: JSON.stringify({ query, variables }),
      method: 'POST',
    };
    let res: Response;

    if (isBrowserRequest) {
      const defaultHeaders = await getDefaultFetchHeaders();
      const headers = options ?? defaultHeaders;
      res = await fetch(GRAPHQL_ENDPOINT, {
        ...defaultFetchOptions,
        headers: headers as globalThis.RequestInit['headers'],
      });
    } else {
      // Server-side request
      res = await fetch(GRAPHQL_ENDPOINT, {
        ...defaultFetchOptions,
        headers: options as globalThis.RequestInit['headers'],
      });
    }

    let json = await res.json();

    // for testing partial data response with errors
    if (json.data?.errors && json.data?.data) {
      json = json.data;
    }

    if (json.errors) {
      if (SENTRY_DSN) {
        for (const error of json.errors) {
          // See https://blog.sentry.io/2020/07/22/handling-graphql-errors-using-sentry
          Sentry.withScope(scope => {
            // Grab the custom query name.
            scope.setTag('kind', query.trim().split(' ', 2)[1].split('(', 1)[0]);

            if (error.extensions.statusCode) {
              scope.setTag('rest_api_code', error.extensions.statusCode);
            }

            scope.setExtra('query', query);
            scope.setExtra('variables', variables);
            scope.setExtra('error', error);
            if (error.path) {
              scope.addBreadcrumb({
                category: 'query-path',
                level: 'debug',
                message: error.path.join(' > '),
              });
            }
            const { message, ...rest } = error;
            Sentry.captureException(new GraphQLError(message, rest as GraphQLErrorArgs));
          });
        }
      }

      const isEmptyArray = Array.isArray(json.data) && json.data.length < 1;
      const isEmptyObject = typeof json.data === 'object' && Object.values(json.data).length < 1;
      const isObjectWithOnlyNullValue =
        typeof json.data === 'object' && Object.values(json.data).length === 1 && Object.values(json.data)[0] === null;

      if (!json.data || isEmptyArray || isEmptyObject || isObjectWithOnlyNullValue) {
        throw new Error(JSON.stringify(json.errors));
      }
    }

    return { ...json.data, error: Boolean(json.errors) };
  };
}

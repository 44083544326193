// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"2591"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

import { COMMIT_SHA, IS_STAGING, SENTRY_DSN } from '@/utils/env';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: IS_STAGING ? 'staging' : 'production',
  release: COMMIT_SHA,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
});

import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';

import { useCurrentOrganizationId } from '@/context/organization/useCurrentOrganizationId';
import {
  AdminOrganizationsOrganization,
  GetOrganizationQuery,
  Status2,
  useGetLocationsBaseDataQuery,
  useGetOrganizationQuery,
} from '@/graphql';
import { OrganizationProfiles, parseOrganizationProfiles } from '@/utils/parsers/parseOrganizationProfiles';
import { resolveOrgPrimaryLanguageI18n } from '@/utils/resolve-org-primary-language-i18n';
import { safeString } from '@/utils/strings';
import { MaybeArray } from '@/utils/types';

type ContentType = 'bible' | 'plan' | 'video';

export interface UseOrganizationReturnType {
  /** The parsed address profile of the selected organization. */
  addressProfile: OrganizationProfiles['addressProfile'];
  /** The parsed bible profile of the selected organization. */
  bibleProfile: OrganizationProfiles['bibleProfile'];
  /** The parsed church profile of the selected organization. */
  churchProfile: OrganizationProfiles['churchProfile'];
  /** The parsed content profile of the selected organization. */
  contentProfile: OrganizationProfiles['contentProfile'];
  /** The content types of the parsed content profile. */
  contentTypes: Array<ContentType>;
  /** Get the AuthZ Fully Qualified Resource Name™ for a given organization or location id. */
  getFqrn: (id: string | null | undefined) => string | null | undefined;
  /** Returns true if an organization has multiple locations. */
  hasMultipleLocations?: boolean;
  /** A callback function that invalidates the current organization queries. */
  invalidateQueries: () => void;
  /** The Publishing state of the current organization church profile. */
  isChurchProfilePublished: boolean;
  /** The current error state of the queries that fetch the organization(s) of the authenticated user. */
  isError: boolean;
  /** The current loading state of the queries that fetch the organization(s) of the authenticated user. */
  isLoading: boolean;
  /** The current selected organization. */
  organization?: GetOrganizationQuery['getAdminOrganization'];
  /** The current selected organization id. */
  organizationId?: string;
  /* The current selected organizationId and all subLocation Ids */
  organizationIds?: Array<string>;
  /** All locations for the current organization. */
  organizationLocations?: MaybeArray<AdminOrganizationsOrganization>;
  /** The current status for the selected organization. */
  organizationStatus?: Status2;
}

/**
 * A hook that fetches the selected organization of the authenticated user.
 *
 * @returns
 * - addressProfile - The parsed address profile of the selected organization.
 * - churchProfile - The parsed church profile of the selected organization.
 * - hasMultipleLocations - Returns true if an organization has multiple locations.
 * - isError - If the query attempt resulted in an error.
 * - isLoading - If the query is currently fetching.
 * - organization - The current selected organization.
 * - organizationId - The current selected organization id.
 * - organizationIds - The current selected organizationId and all subLocation Ids
 * - organizationLocations - List of locations for the current organization.
 * - organizationStatus - The current status for the selected organization.
 * - invalidateQueries - A callback function that invalidates the current organization queries.
 *
 * @example
 * ```typescript
 * import { useOrganization } from '@/context/organization/useOrganization';
 *
 * function MyComponent() {
 *   const { organization, addressProfile, ... } = useOrganization();
 *   // return...
 * }
 * ```
 */
export const useOrganization = (): UseOrganizationReturnType => {
  const { organizationId } = useCurrentOrganizationId();
  const queryClient = useQueryClient();

  type GetOrganizationReturnType = Pick<
    UseOrganizationReturnType,
    'hasMultipleLocations' | 'organization' | 'addressProfile' | 'bibleProfile' | 'churchProfile' | 'contentProfile'
  >;

  const {
    isLoading: isLoadingGetOrganization,
    isError,
    data,
  } = useGetOrganizationQuery(
    { organizationId: safeString(organizationId) },
    {
      enabled: Boolean(organizationId),
      select: (data): GetOrganizationReturnType => {
        const organization = data.getAdminOrganization;

        if (organization) {
          organization.i18ns = resolveOrgPrimaryLanguageI18n(organization);
        }

        const hasMultipleLocations = Boolean(organization?.locations?.data && organization.locations.data.length > 0);
        const { addressProfile, bibleProfile, churchProfile, contentProfile } = parseOrganizationProfiles(
          organization?.profiles
        );

        return {
          addressProfile,
          bibleProfile,
          churchProfile,
          contentProfile,
          hasMultipleLocations,
          organization,
        };
      },
      staleTime: 5 * 1000,
    }
  );

  const { data: organizationLocations, isLoading: isLoadingOrganizationLocations } = useGetLocationsBaseDataQuery(
    { parentOrganizationId: safeString(organizationId) },
    {
      enabled: Boolean(organizationId?.length),
      select: data => data.getAdminOrganizations?.data,
    }
  );

  const organizationIds = React.useMemo(
    () =>
      [organizationId, organizationLocations?.map(location => location?.id)].filter(Boolean).flat() as Array<string>,
    [organizationId, organizationLocations]
  );

  const invalidateQueries = React.useCallback(() => {
    queryClient.invalidateQueries(useGetOrganizationQuery.getKey({ organizationId: safeString(organizationId) }));
    queryClient.invalidateQueries(
      useGetLocationsBaseDataQuery.getKey({ parentOrganizationId: safeString(organizationId) })
    );
  }, [organizationId, queryClient]);

  const getFqrn = React.useCallback(
    (id: string | null | undefined) => {
      if (!id) {
        return null;
      }

      return id === organizationId
        ? data?.organization?.fqrn
        : organizationLocations?.find(location => location?.id === id)?.fqrn;
    },
    [data?.organization?.fqrn, organizationId, organizationLocations]
  );

  const profileTypes = {
    partner_portal_organization: 'plan',
    video_publisher: 'video',
    yv_bible_publisher: 'bible',
  } as const;

  const contentTypes = [
    ...(data?.contentProfile?.externalIds?.map(profile => {
      // Override type because mesh's generated type is wrong. (All caps verses lower case)
      // `toLowerCase` just in case something changes in the mesh.
      const profileType = profile.type.toLowerCase() as unknown as keyof typeof profileTypes;
      return profileTypes[profileType];
    }) ?? []),
    ...(data?.bibleProfile ? [profileTypes.yv_bible_publisher] : []),
  ];

  return {
    addressProfile: data?.addressProfile,
    bibleProfile: data?.bibleProfile,
    churchProfile: data?.churchProfile,
    contentProfile: data?.contentProfile,
    contentTypes,
    getFqrn,
    hasMultipleLocations: data?.hasMultipleLocations,
    invalidateQueries,
    isChurchProfilePublished: Boolean(
      data?.churchProfile?.status === Status2.PUBLISHED || data?.churchProfile?.status === Status2.HIDDEN
    ),
    isError,
    isLoading: Boolean((isLoadingGetOrganization || isLoadingOrganizationLocations) && organizationId),
    organization: data?.organization,
    organizationId,
    organizationIds,
    organizationLocations,
    organizationStatus: data?.churchProfile?.status,
  };
};

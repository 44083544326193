type LowerSnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends ' ' ? '_' : Lowercase<T>}${LowerSnakeCase<U>}`
  : S;

/**
 * Converts a string to lower snake case, useful for mapping enums to translation keys.
 *
 * @example
 * ```typescript
 * const key = 'Hello World';
 * const result = getTranslationKey(key); // 'hello_world'
 * ```
 */
export const getTranslationKey = <T extends string>(key: T) =>
  key.toLowerCase().replace(/\s/g, '_') as LowerSnakeCase<T>;

/**
 * Produces a safe displayable string, not allowing undefined or null.
 *
 * @param val - Value to work on.
 *
 * @example
 * ```typescript
 * // 1. evaluatedValue will be assigned an empty string ''.
 * const someTestValue = undefined;
 * const evaluatedValue = safeString(someTestValue as string);
 *
 * // 2. Numbers are converted to strings. Equivalent to calling someTestValue.toString().
 * const someTestValue = 1004;
 * const evaluatedValue = safeString(someTestValue);
 * ```
 */
export const safeString = (val: string | number | boolean | undefined | null): string => {
  if (typeof val === 'boolean') {
    return String(val);
  }

  if (typeof val === 'number') {
    return val.toString();
  }

  // If a string is passed in with already undesired values, convert them to '';
  if (val === 'undefined' || val === 'null') {
    return '';
  }

  return val ?? '';
};

import { LocaleHeader } from '@/graphql';

import { allLocaleCodes } from '../constants';

/**
 * A function that converts an api LocaleHeader into an App I18NS Locale.
 *
 * @param localeHeader - The locale header to convert.
 * @returns - The App Locale equivalent of the localeHeader.locale param.
 * @example
 * ```typescriptreact
 * import { convertLocaleHeaderToAppLocale } from '@/utils/localization';
 * import { useLocales } from '@/hooks/useLocales';
 *
 * const ExampleComponent = () => {
 *   const { localeHeaders } = useLocales();
 *
 *  // Converts API LocaleHeader into an enabled app language.
 *   const locale = convertLocaleHeaderToAppLocale(localeHeaders[0])
 *  ...
 * }
 * ```
 */
export const convertLocaleHeaderToAppLocale = (localeHeader: LocaleHeader): string => {
  const localeToConvert = localeHeader.locale as string;

  // Search allLocaleCodes constant for localeToConvert
  const localeCode = allLocaleCodes.find(locale => locale.includes(localeToConvert));

  // Fallback to check root locale and return the first option.
  const rootLocale = new Intl.Locale(localeToConvert);
  const rootLocaleCode = allLocaleCodes.find(locale => locale.includes(rootLocale.language as string));

  // Fallback to localeToConvert param
  return localeCode || rootLocaleCode || localeToConvert;
};

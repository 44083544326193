import * as Sentry from '@sentry/nextjs';
import { useQueryClient } from '@tanstack/react-query';
import { useAuth } from '@youversion/auth';
import { Status } from '@youversion/types';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/compat/router';
import { createContext, useCallback, useEffect, useState } from 'react';

import { useQueryParams } from '@/hooks/use-query-params';
import { safeString } from '@/utils/strings';

function resetOrganization(id: string) {
  Sentry.setTag('organization_id', id);
  deleteCookie('organization_id', { path: '/' });
  setCookie('organization_id', id, { path: '/' });
}

type OrganizationContext = {
  /** Destroy organization in context. */
  nukeFromOrbit: () => void;
  /** The current selected organization id. */
  organizationId?: string;
  /** A callback function to change the selected organization and trigger a refetch. */
  selectOrganization: (args: { id: string; pushTo?: string }) => void;
};

/**
 * A React context that handles the selected organization(s) of the authenticated user.
 */
export const OrganizationContext = createContext<OrganizationContext | null>(null);

export function OrganizationProvider({ children }: { children: React.ReactNode }) {
  const queryClient = useQueryClient();
  const router = useRouter();
  const { organization_id: organizationUrlParam, is_account_invite: isAccountInvite } = useQueryParams();
  const { isSignedIn } = useAuth();
  const organization_id = getCookie('organization_id');
  const isAccountInviteUrl = isAccountInvite === 'true';

  // save organizationId only if it's not from an invite url.
  const organizationIdFromParam =
    organizationUrlParam && !isAccountInviteUrl ? safeString(organizationUrlParam) : undefined;
  const [organizationId, setOrganizationId] = useState(() => safeString(organizationIdFromParam ?? organization_id));
  const [loading, setLoading] = useState<Status>('pending');

  // Set organization_id cookie on load.
  useEffect(() => {
    if (isSignedIn && organizationId && loading === 'pending') {
      resetOrganization(organizationId);
      setLoading('resolved');
    }
  }, [isSignedIn, organizationId, loading]);

  const selectOrganization: OrganizationContext['selectOrganization'] = ({ id, pushTo }) => {
    resetOrganization(id);
    queryClient.clear();
    setOrganizationId(id);
    if (router && pushTo) {
      router.push(pushTo);
    }
  };

  const nukeFromOrbit = useCallback(() => {
    Sentry.setTag('organization_id', null);
    deleteCookie('organization_id', { path: '/' });
    queryClient.clear();
    setOrganizationId('');
  }, [queryClient]);

  return (
    <OrganizationContext.Provider
      value={{
        nukeFromOrbit,
        organizationId,
        selectOrganization,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
}

/**
 * A hook that handles the selected organizationId of the application.
 *
 * @returns
 * - organizationId -  The current selected organizationId.
 * - selectOrganization - A function to change the current organizationId.
 *
 * @throws Error if this hook is used outside of {@link OrganizationProvider}.
 * @example
 * ```typescript
 * import { useCurrentOrganizationId } from '@/context/organization/useCurrentOrganizationId';
 *
 * function MyComponent() {
 *   const { organizationId, selectOrganization } = useCurrentOrganizationId();
 *   // return...
 * }
 * ```
 */

export { useOrganizationsForUser } from './useOrganizationsForUser';

import { enabledRootLocales } from './constants';

/**
 * Filters enabledRootLocales from allLocaleNames.
 *
 * @see {@link enabledRootLocales}.
 *
 * @example
 * ```typescript
 * getEnabledLocales();
 *
 * // returns ['en-AU', 'en-CA', 'en-GB', 'en-US', ...]
 * ```
 */
export const getEnabledLocales = (): Array<string> => {
  return Object.values(enabledRootLocales);
};

import { AdminOrganizationsI18n, AdminOrganizationsOrganization } from '@/graphql';

import notEmpty from '../notEmpty';
import { safeString } from '../strings';

export function resolveOrgPrimaryLanguageI18n(
  organization: AdminOrganizationsOrganization
): Array<AdminOrganizationsI18n> {
  const primaryLanguage = organization.primaryLanguage ?? 'en';
  const i18ns = organization.i18ns?.filter(notEmpty) ?? [];

  const doesPrimaryLanguageI18nExist = i18ns.find(i18n => i18n?.languageTag === primaryLanguage);
  if (doesPrimaryLanguageI18nExist) {
    return i18ns;
  }

  // Create a new i18n object with the primary language
  const primaryLanguageI18n: AdminOrganizationsI18n = {
    __typename: 'AdminOrganizationsI18n',
    backgroundColor: safeString(organization.backgroundColor),
    description: '',
    languageTag: primaryLanguage,
    logo: organization.logo,
    name: organization.name,
    url: safeString(organization.websiteUrl),
  };

  return [primaryLanguageI18n, ...i18ns];
}

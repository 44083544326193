import dayjsLocales from 'dayjs/locale.json';

import { LanguageType } from '@/hooks/locales';

type LanguageTagsType = {
  langTagDayJS: string;
  langTagYV: string;
};

export const getDayJSLanguageTags = (allYVLaguages: Array<LanguageType>): Array<LanguageTagsType> => {
  const allYVKeys = allYVLaguages.map(lang => lang.apiTag.toLowerCase().replace('_', '-')).sort();
  const allDayJSKeys = dayjsLocales.map(lang => lang.key).sort();

  const keysInYVNotInDayjs = allYVKeys.filter(key => !allDayJSKeys.includes(key));

  //  Filter all corresponding tags from YV in dayJS

  const equalLangTags = allYVKeys.reduce((acc: Array<LanguageTagsType>, lang) => {
    const langTagDayJS = allDayJSKeys.find(langInDayjs => langInDayjs === lang) ?? '';

    if (langTagDayJS) {
      acc.push({
        langTagDayJS,
        langTagYV: lang,
      });
    }

    return acc;
  }, []);

  //  Finding corresponding from keysInYVNotInDayjs just by language

  const withoutSpecificCountryInDayJS = keysInYVNotInDayjs.reduce((acc: Array<LanguageTagsType>, lang) => {
    const firstTagPart = lang.split('-')[0];
    const langTagDayJS = allDayJSKeys.find(langInDayjs => langInDayjs === firstTagPart);

    if (langTagDayJS) {
      acc.push({
        langTagDayJS,
        langTagYV: lang,
      });

      const indexToRemove = keysInYVNotInDayjs.findIndex(langTag => langTag === lang);
      keysInYVNotInDayjs.splice(indexToRemove, 1);
    }

    return acc;
  }, []);

  //  Finding in dayJS any starting with language

  const withSpecificCountryInDayJS = keysInYVNotInDayjs.reduce((acc: Array<LanguageTagsType>, lang) => {
    const langTagDayJS = allDayJSKeys.find(langInDayjs => langInDayjs.startsWith(lang));

    if (langTagDayJS) {
      acc.push({
        langTagDayJS,
        langTagYV: lang,
      });

      const indexToRemove = keysInYVNotInDayjs.findIndex(langTag => langTag === lang);
      keysInYVNotInDayjs.splice(indexToRemove, 1);
    }

    return acc;
  }, []);

  return [...equalLangTags, ...withoutSpecificCountryInDayJS, ...withSpecificCountryInDayJS];
};

import { dehydrate, FetchQueryOptions, QueryClient, QueryFunction, QueryKey } from '@tanstack/react-query';

export type PrefetcherProps = {
  enabled?: Boolean;
  options?: FetchQueryOptions;
  queryFn: QueryFunction;
  queryKey: QueryKey;
};

export type PrefetcherOptions = {
  enabled?: Boolean;
};

/**
 * This function handles server side prefetching a query.
 *
 * @remarks - This function should be used within `getServersideProps` or `getInitialProps`
 *
 * @param queries - An array of {@link PrefetcherProps}.
 * @param prefetcherOptions - An object of {@link PrefetcherOptions}.
 *
 * {@link PrefetcherProps}.
 * @param enabled - Set this to false to disable this query from automatically running.
 * @param options - The same options that are passed to React Query's `useQuery` hook.
 * @param queryFn - The fetcher function used to fetch a query.
 * @param queryKey - A unique key used for caching query data.
 *
 * {@link PrefetcherOptions}.
 * @param enabled - Set this to false to disable all queries from automatically running.
 *
 * @returns `dehydratedState` - The state to hydrate into the client.
 *
 * @example
 * getInitialProps
 * ```typescript
 *  import { serverSidePrefetcher } from '@/api/serverSidePrefetcher';
 *
 *  MyApp.getInitialProps = async (appContext: AppContext) => {
 *    // ...
 *    const organization_id = ...
 *    const requestHeaders = getSSRHeaders(appContext.ctx);
 *    const dehydratedState = await serverSidePrefetcher([{
 *      queryFn: useGetOrganizationQuery.fetcher({ organizationId: organization_id }, requestHeaders),
 *      queryKey: useGetOrganizationQuery.getKey({ organizationId: organization_id }),
 *    }], { enabled: Boolean(organization_id) });
 *    appProps.pageProps.dehydratedState = dehydratedState;
 *    // ...
 *  }
 * ```
 *
 * getServerSideProps
 * ```typescript
 *  import { serverSidePrefetcher } from '@/api/serverSidePrefetcher';
 *
 *  export const getServerSideProps: GetServerSideProps = async (context) => {
 *    // ...
 *    const organization_id = ...
 *    const requestHeaders = getSSRHeaders(appContext.ctx);
 *    const dehydratedState = await serverSidePrefetcher([{
 *      queryFn: useGetOrganizationQuery.fetcher({ organizationId: organization_id }, requestHeaders),
 *      queryKey: useGetOrganizationQuery.getKey({ organizationId: organization_id }),
 *    }], { enabled: Boolean(organization_id) });
 *    return {
 *      props: {
 *        dehydratedState
 *      }
 *   }
 * };
 * ```
 */
export const serverSidePrefetcher = async (
  queries: Array<PrefetcherProps>,
  { enabled }: PrefetcherOptions | undefined = { enabled: true }
) => {
  if (enabled) {
    const queryClient = new QueryClient();
    await Promise.all(
      queries.map(query => {
        const { enabled: isQueryEnabled = true } = query;
        if (isQueryEnabled) {
          return queryClient.prefetchQuery(
            query.queryKey,
            query.queryFn,
            query.options || { cacheTime: 5 * 1000, staleTime: 5 * 1000 }
          );
        }
      })
    );
    const dehydratedState = JSON.parse(JSON.stringify(dehydrate(queryClient)));
    queryClient.clear();

    return dehydratedState;
  }
  return null;
};

/**
 * This function returns the localized string from i18n data and falls back to `default`.
 *
 * @param i18nData -  I18n data that is returned from the API.
 * @param locale - The locale to get the correct i18n data. (e.g. 'en' | 'en-US').
 * @example
 * ```typescript
 *  import { getLocalizedStringFromJson } from '@/utils/getLocalizedStringFromJson';
 *
 * const currentLanguage = 'es';
 *
 * const jsonDataFromTheApi = {
 *    en: 'Value in english',
 *    es: 'Value in spanish',
 *    default: 'Fallback default value'
 * };
 *
 * const localizedValue = getLocalizedStringFromJson(jsonDataFromTheApi, currentLanguage);
 * @returns 'Value in spanish'
 * ```
 */
export const getLocalizedStringFromJson = (i18nData: Record<string, string>, locale: string) => {
  if (i18nData) {
    const rootLanguage = new Intl.Locale(locale).language || 'en';
    if (locale in i18nData) {
      return i18nData[locale];
    }
    if (rootLanguage in i18nData) {
      return i18nData[locale];
    }
    return i18nData['default'];
  }
};

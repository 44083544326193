import localeData from 'dayjs/plugin/localeData';

import dayjs from '@/lib/dayjs';

dayjs.extend(localeData);

/**
 * Returns an array of names of days with Sunday at index 0 localized to the current locale.
 **/
export const useLocalizedDays = (): Array<string> => {
  return dayjs.weekdays();
};

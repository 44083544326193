export * from './churches';
export * from './countries';
export * from './geography';
export * from './insights';
export * from './locales';
export * from './permissions';
export * from './posts';
export * from './profiles';
export * from './roles';
export * from './urls';

/** RegEx for ColorSelect Hex validation. */
export const HexRegExp = /^#[0-9A-F]{6}$/i;

export const ENTER_KEY_CODE = 'Enter';

export const NameRegExp = /^[a-z ,.'-]+$/i;

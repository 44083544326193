import { useTranslation } from 'react-i18next';

import { LocaleHeader, useGetLocaleHeadersQuery } from '@/graphql';
import { LocaleCode, LocaleCodeToLanguageTranslationKey } from '@/utils/constants';
import { filterObjectsDistinctBy } from '@/utils/filterObjectsDistinctBy';
import { getEnabledLocales } from '@/utils/locale-utils';
import { convertAppLocaleToLocaleHeader, convertLocaleHeaderToAppLocale } from '@/utils/localization';
import notEmpty from '@/utils/notEmpty';

import { useLocalizedLanguages } from '..';

/**
 * Locales that shouldn't be resolved in the application.
 * Locales in this array have duplicate apiLanguageTag's in the API.
 * */
export const ApiLocalesToUnresolve = ['en-GB'];

export interface LanguageType {
  /** The API language code. Saving orgaizantions & descriptions, searching plans, etc. */
  apiTag: string;
  /** The localName of the current locale. */
  displayName: string;
  /** Crowdin/i18next language code. */
  locale?: string;
  /**
   * Language name to display for the language selector in rtl format.
   *
   * @example
   * ```typescript
   * 'Spanish - Español (América Latina)'
   * ```
   **/
  localizedLanguageLabel: string;
}

export interface UseLocalesReturnType {
  /**
   * An array of all locales returned from the api in the LanguageType format.
   * Note: Generally used with API related LanguageSelect's.
   * */
  allLanguages: Array<LanguageType>;
  /**
   * An array of the app enabled locales in the LanguageType format.
   * Note: Generally used for App LanguageSelect.
   * */
  enabledLanguages: Array<LanguageType>;
  /** The loading status of the getLocaleHeaders query. */
  isLoading: boolean;
  /** The localeHeaders returned from the API with duplicates removed. */
  localeHeaders: Array<LocaleHeader> | undefined;
}

export const useLocales = (): UseLocalesReturnType => {
  const enabledLocales = getEnabledLocales();
  const { getLocalizedLanguageName, localizedLanguages } = useLocalizedLanguages();
  const { t } = useTranslation();

  const { data: apiLocaleHeaders, isLoading } = useGetLocaleHeadersQuery(
    {},
    {
      select: data => {
        const allHeaders = data.getLocaleHeaders?.locales?.filter(notEmpty);
        const headers = allHeaders?.filter(
          locale => !ApiLocalesToUnresolve.includes(locale.locale!)
        ) as Array<LocaleHeader>;
        return filterObjectsDistinctBy(headers, 'apiLanguageTag') || [];
      },
    }
  );

  /* eslint-disable jsdoc/require-example */
  /**
   * This internal function formats the LocaleHeaders returned from the API, into a {@link LanguageType} format.
   *
   * @internal
   * @returns - An array of {@link LanguageType}'s.
   */
  const formatLocaleHeaders = (localeHeaders: Array<LocaleHeader>): Array<LanguageType> => {
    return localeHeaders.map(localeHeader => {
      const locale = convertLocaleHeaderToAppLocale(localeHeader);
      // Some display names are not handled properly by cldr, so we use i18next for those.
      const languageTranslationKey = LocaleCodeToLanguageTranslationKey.get(locale as LocaleCode);
      const appLocalizedLanguageName = languageTranslationKey ? t(`languages.${languageTranslationKey}`) : null;

      const rootLocale = new Intl.Locale(locale);
      const displayName = new Intl.DisplayNames(rootLocale.language, {
        // @ts-ignore
        languageDisplay: 'standard',
        style: 'long',
        type: 'language',
      }).of(locale);

      const localizedLanguageName = getLocalizedLanguageName(localeHeader);

      return {
        apiTag: localeHeader.apiLanguageTag,
        displayName,
        locale: convertLocaleHeaderToAppLocale(localeHeader),
        localizedLanguageLabel: languageTranslationKey
          ? getRTLlabel(localeHeader.localName, appLocalizedLanguageName)
          : localizedLanguageName,
      } as LanguageType;
    });
  };

  /* eslint-disable jsdoc/require-example */
  /**
   * This internal function formats the app enabled locales, into a {@link LanguageType} format.
   *
   * @internal
   * @returns - An array of {@link LanguageType}'s.
   */
  const formatEnabledLanguages = (localeHeaders: Array<LocaleHeader>) => {
    return enabledLocales.map(locale => {
      const rootLocale = new Intl.Locale(locale);
      let displayName = new Intl.DisplayNames(rootLocale.language, {
        // @ts-ignore
        languageDisplay: 'standard',
        style: 'long',
        type: 'language',
      }).of(locale);

      const localeHeader = convertAppLocaleToLocaleHeader({ locale, localeHeaders });
      const localName = localeHeader?.localName;

      // Some display names are not handled properly by cldr, so we use i18next for those.
      const languageTranslationKey = LocaleCodeToLanguageTranslationKey.get(locale as LocaleCode);

      const localizedLanguageName =
        (languageTranslationKey ? t(`languages.${languageTranslationKey}`) : false) || // i18next
        localizedLanguages[rootLocale.language!] || // Try the full locale: 'en-US' with dash
        localizedLanguages[locale] || // Try the short locale: 'en' without dash
        displayName;

      if (localName) {
        displayName = displayName?.includes(localName) ? displayName : localName;
      }

      return {
        apiTag: localeHeader?.apiLanguageTag,
        displayName,
        locale,
        localizedLanguageLabel: getRTLlabel(displayName, localizedLanguageName || 'en'),
      } as LanguageType;
    });
  };

  const getRTLlabel = (localName: LocaleHeader['localName'], localizedLanguageName: string | null): string => {
    if (localizedLanguageName && localName?.split(' ').includes(localizedLanguageName)) {
      return localName;
    }
    return `${localizedLanguageName} - ${localName}`;
  };

  const enabledLanguages = apiLocaleHeaders ? formatEnabledLanguages(apiLocaleHeaders) : [];
  const allLanguages = apiLocaleHeaders ? formatLocaleHeaders(apiLocaleHeaders) : [];

  return {
    allLanguages,
    enabledLanguages,
    isLoading,
    localeHeaders: apiLocaleHeaders,
  };
};

import { useSignInLink } from '@youversion/auth';
import { useRouter } from 'next/compat/router';

import { EnabledLocale } from '@/utils/localization/enabled-locales';

/**
 * Properly forms a sign in link, with proper locale tag passed along.
 *
 * @returns The localized sign in link.
 *
 * @example
 * ```tsx
 * import { useLocalizedSignInLink } from '@/utils'
 *
 * function MyComponent(origin) {
 *   const localizedSignInLink = useLocalizedSignInLink();
 *
 *   return <a href={localizedSignInLink}>Sign In</a>;
 * }
 * ```
 */
export function useLocalizedSignInLink() {
  const router = useRouter();

  const languageTag = router?.locale as EnabledLocale | undefined;
  const languageTagUrlPart = languageTag && languageTag !== 'en-US' ? `${languageTag}/` : '';

  return useSignInLink(
    `${process.env.NEXT_PUBLIC_BASE_URL}/authenticate${`?from=${encodeURIComponent(
      `${languageTagUrlPart}${router?.asPath ?? ''}`
    )}`}`,
    {
      appRef: 'youversion-connect',
      intent: router?.query?.utm_ref?.includes('yv4c') ? 'churches' : '',
      locale: languageTag,
    }
  );
}

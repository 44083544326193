import {
  AddressProfileDataType,
  BibleProfileDataType,
  ChurchProfileDataType,
  ContentProfileDataType,
} from '@/api/organizations';
import { OrganizationsOrganizationProfiles } from '@/graphql';

import { organizationProfileTypes } from '../constants';

export type OrganizationProfiles = {
  addressProfile?: AddressProfileDataType;
  bibleProfile?: BibleProfileDataType;
  churchProfile?: ChurchProfileDataType;
  contentProfile?: ContentProfileDataType;
};

/**
 * Parses the type of `JSON` or `any` into typescript types.
 *
 * @param profile - The profile of an organization {@link OrganizationsOrganizationProfiles}.
 *
 * @returns - {@link OrganizationProfiles}.
 *
 * @see {@link AddressProfileDataType} For the returned `OrganizationProfile['addressProfile']` type.
 * @see {@link BibleProfileDataType} For the returned `OrganizationProfile['bibleProfile']` type.
 * @see {@link ChurchProfileDataType} For the returned `OrganizationProfile['churchProfile']` type.
 * @example
 * ```typescript
 * import { parseOrganizationProfiles } from '@/utils/parseOrganizationProfiles'
 *
 * const { addressProfile, bibleProfile, churchProfile } = parseOrganizationProfiles(organization.profiles);
 * ```
 */
export const parseOrganizationProfiles = (profile?: OrganizationsOrganizationProfiles | null): OrganizationProfiles => {
  let addressProfile, bibleProfile, churchProfile, contentProfile;

  if (profile && Array.isArray(profile.data)) {
    addressProfile = profile.data.find(
      data => data.type === organizationProfileTypes.address
    ) as AddressProfileDataType;
    bibleProfile = profile.data.find(data => data.type === organizationProfileTypes.bible) as BibleProfileDataType;
    churchProfile = profile.data.find(data => data.type === organizationProfileTypes.church) as ChurchProfileDataType;
    contentProfile = profile.data.find(
      data => data.type === organizationProfileTypes.content
    ) as ContentProfileDataType;
  }

  return {
    addressProfile,
    bibleProfile,
    churchProfile,
    contentProfile,
  };
};

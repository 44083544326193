import { SelectOption } from '../types';

export const LOCATION_LEADER_TITLE_MAX_LENGTH = 50;
export const LOCATION_DESCRIPTION_MAX_LENGTH = 280;
export const LOCATION_NAME_MAX_LENGTH = 80;
export const SCHEDULE_DESCRIPTION_MAX_LENGTH = 25;
export const POSTS_TITLE_MAX_LENGTH = 60;
export const POSTS_MESSAGE_MAX_LENGTH = 2000;

export const weeklyAttendanceRanges: { [key: string]: SelectOption } = Object.freeze({
  99: { label: '1-99', value: 99 },
  249: { label: '100-249', value: 249 },
  499: { label: '250-499', value: 499 },
  999: { label: '500-999', value: 999 },
  2499: { label: '1000-2,499', value: 2499 },
  4999: { label: '2,500-4,999', value: 4999 },
  9999: { label: '5,000-9,999', value: 9999 },
  10000: { label: '10,000+', value: 10000 },
});

import dayjsSupportedLangs from 'dayjs/locale.json';

export const getValidDayJSLocaleKey = (appLanguage: string) => {
  const lowerAppLang = appLanguage.toLowerCase();
  const keys = dayjsSupportedLangs.map(lang => lang.key.toLowerCase());
  if (keys.includes(lowerAppLang)) {
    return lowerAppLang;
  }
  if (keys.includes(lowerAppLang.slice(0, 2))) {
    return lowerAppLang.slice(0, 2);
  }

  // Norwegian is a special case
  if (appLanguage === 'no-NO') {
    return 'nb';
  }

  // Filipino is a special case
  if (appLanguage === 'fil-PH') {
    return 'tl-ph';
  }
  return 'en';
};

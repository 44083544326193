import { LoadingSpinner } from '@youversion/design-system-components';
import { useTranslation } from 'next-i18next';
import * as React from 'react';

/**
 * The full-page loading spinner component.
 *
 * @example
 * ```typescript
 * import { useAuth } from '@youversion/auth';
 * import { FullPageLoader } from '@/components/loaders';
 *
 * const MyPage: NextPage = () => {
 *   const { isLoading } = useAuth();
 *
 *   if (isLoading) {
 *     return <FullPageLoader />;
 *   }
 *
 *   // ...return page content
 * }
 * ```
 */
export function FullPageLoader() {
  const { t } = useTranslation();
  return (
    <div className='flex items-center justify-center w-screen h-screen'>
      <LoadingSpinner aria-label={t('loading')} color='text-yv-red' />
    </div>
  );
}

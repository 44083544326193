import { useRouter } from 'next/compat/router';

import { PlanDimension } from '@/hooks/insights/useContentCubeQuery';
import { CompareByOptions, FilterByOptions } from '@/utils/types';

type QueryObject = Record<string, string> & {
  compareBy?: CompareByOptions;
  filterBy?: FilterByOptions;
  growth?: '7' | '28';
  order?: 'desc' | 'asc';
  planId?: string | undefined;
  sortBy?: PlanDimension;
  trend?: '90' | '12';
  version?: string | undefined;
};
/**
 * Hook for easily retrieving params from the next router query.
 *
 * @example
 * ```typescript
 * // Retrieve locationId and id params from the URL
 * const { locationId, id } = useQueryParams();
 * ```
 */
export const useQueryParams = () => {
  const router = useRouter();
  if (router) {
    const query = router.query;
    const destructibleObject: QueryObject = {};
    const keys = Object.keys(query);
    keys.forEach(key => {
      if (typeof query[key] === 'string') {
        destructibleObject[key] = query[key] as string;
      }
    });
    return destructibleObject;
  }
  return {};
};

import cldrData from 'cldr-data/availableLocales.json';
import { useTranslation } from 'next-i18next';
import * as React from 'react';

import { LocaleHeader } from '@/graphql';

/**
 * This hook dynamically imports a record of all language names localized to the current locale.
 * A utility function is provided to get the localized language name for a given localeHeader.
 */
export const useLocalizedLanguages = () => {
  const { i18n } = useTranslation();
  const { currentLanguage } = React.useMemo(
    () => ({
      // @ts-expect-error: Property 'language' does not exist on type 'i18n'
      currentLanguage: i18n.language,
    }),
    [i18n]
  );

  const [languages, setLanguages] = React.useState<Record<string, string>>({});

  /**
   * Get the localized language name for a given localeHeader.
   *
   * @param localeHeader - The localeHeader from the `useGetLocaleHeadersQuery` to get the localized language name for.
   */
  const getLocalizedLanguageName = React.useCallback(
    (localeHeader: LocaleHeader) => {
      if (!localeHeader.locale || !localeHeader.apiLanguageTag || !localeHeader.locale || !localeHeader.localName) {
        return `${localeHeader.localName}`;
      }

      const rootLocale = new Intl.Locale(localeHeader.locale).language;
      const localizedLanguageName =
        languages[localeHeader.apiLanguageTag] ||
        languages[localeHeader.locale] ||
        (rootLocale && languages[rootLocale]) ||
        localeHeader.localName;

      if (localizedLanguageName === localeHeader.localName) {
        return localizedLanguageName;
      }

      return `${localizedLanguageName} - ${localeHeader.localName}`;
    },
    [languages]
  );

  const getLanguages = React.useCallback(async (language: string) => {
    const data = await import(`cldr-data/main/${language}/languages.json`);
    const selectedLanguages = data.main[language].localeDisplayNames.languages;
    return setLanguages(selectedLanguages);
  }, []);

  React.useEffect(() => {
    const rootLanguage = new Intl.Locale(currentLanguage).language;
    (async () => {
      if (cldrData.availableLocales.includes(currentLanguage)) {
        await getLanguages(currentLanguage);
      } else if (rootLanguage && cldrData.availableLocales.includes(rootLanguage)) {
        await getLanguages(rootLanguage);
      } else {
        await getLanguages('en');
      }
    })();
  }, [currentLanguage, getLanguages]);

  return { getLocalizedLanguageName, localizedLanguages: languages };
};

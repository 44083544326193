import { QueryFunction, QueryKey } from '@tanstack/react-query';

import { PrefetcherOptions, PrefetcherProps } from './server-side-prefetcher';

// This type extracts parameter types of a function and the `any` is safe to ignore.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;

/**
 * This function is meant to be passed into @see [serverSidePrefetcher](./server-side-prefetcher.ts).
 *
 * @remarks - This function should be used within `getServersideProps` or `getInitialProps`
 *
 * @param useQueryFn - Any of the generate useQuery hooks.
 * @param requestHeader - Server-side request headers.
 * @param variables - The variables required for the `useQueryFn`.
 * @param prefetcherOptions - An object of {@link PrefetcherOptions}.
 *
 * {@link PrefetcherOptions}.
 * @param enabled - Set this to false to disable this query from automatically running.
 *
 * @returns `PrefetcherProps` - The object to be passed into the `serverSidePrefetcher`.
 * @see {@link PrefetcherProps}.
 *
 * @example
 * getInitialProps
 * ```typescript
 *  import { generateServerSidePrefetcherQuery, serverSidePrefetcher } from '@/utils/server-side';
 *
 *  MyApp.getInitialProps = async (appContext: AppContext) => {
 *    // ...
 *    const requestHeaders = getSSRHeaders(appContext.ctx);
 *    const dehydratedState = await serverSidePrefetcher([
 *      generateServerSidePrefetcherQuery(useGetOrganizationQuery, requestHeaders,
 *        { organizationId: '1234' })
 *    ]);
 *    appProps.pageProps.dehydratedState = dehydratedState;
 *    // ...
 *  }
 * ```
 *
 * getServerSideProps
 * ```typescript
 *  import { generateServerSidePrefetcherQuery, serverSidePrefetcher } from '@/utils/server-side';
 *
 *  export const getServerSideProps: GetServerSideProps = async (context) => {
 *    // ...
 *    const organizationId = ...
 *    const requestHeaders = getSSRHeaders(appContext.ctx);
 *    const dehydratedState = await serverSidePrefetcher([
 *      generateServerSidePrefetcherQuery(useGetOrganizationQuery, requestHeaders,
 *        { organizationId }, { enabled: organizationId }),
 *      ]);
 *    return {
 *      props: {
 *        dehydratedState
 *      }
 *   }
 * };
 * ```
 */
export function generateServerSidePrefetcherQuery<
  T extends {
    fetcher: (variables: TVariables, options?: HeadersInit) => QueryFunction<unknown, QueryKey>;
    getKey: (variables: TVariables) => QueryKey;
  },
  TVariables = ArgumentTypes<T['fetcher']>[0]
>(
  useQueryFn: T,
  requestHeaders: Headers,
  variables?: TVariables,
  { enabled }: PrefetcherOptions | undefined = { enabled: true }
): PrefetcherProps {
  return {
    enabled: enabled,
    queryFn: useQueryFn.fetcher(variables!, requestHeaders),
    queryKey: useQueryFn.getKey(variables!),
  };
}

// This is the only file that `dayjs` import is allowed
// eslint-disable-next-line no-restricted-imports
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

// This is a reliable approach to guarantee that chunks created by code splitting in production
// that have dayjs always include the extensions with the dayjs.

dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(minMax);

// eslint-disable-next-line no-restricted-imports
export { Dayjs } from 'dayjs';
export default dayjs;

import { AdminOrganizationsI18n, I18ns3ListItem } from '@/graphql';

import { Maybe, MaybeArray } from '../types';

interface Params {
  i18nsItems?: MaybeArray<AdminOrganizationsI18n> | MaybeArray<I18ns3ListItem>;
  languageTag: Maybe<string>;
}

/**
 * The getPrimaryLanguageValueFromI18NS function is used to return the item
 * from the array with the matching language.
 *
 * @param i18nsItems - List of translation items.
 * @param languageTag - The language tag to search for.
 *
 * @returns `i18nsItems[index]` that matches the `languageTag`.
 * @returns `i18nsItems[0]` if no match is found.
 * @returns `undefined` if `i18nsItems` is not an array with a length greater than 0.
 *
 * @example
 * ```typescriptreact
 * import { getPrimaryLanguageValueFromI18NS } from '@/utils/localization';
 *
 * const primaryValue = getPrimaryLanguageValueFromI18NS({i18nsItems: organization.i18ns, languageTag: organization.primaryLanguage});
 * ```
 */
export const getPrimaryLanguageValueFromI18NS = ({
  i18nsItems,
  languageTag,
}: Params): AdminOrganizationsI18n | undefined => {
  if (!Array.isArray(i18nsItems) || i18nsItems.length === 0) {
    return undefined;
  }

  const organizationPrimaryLanguageIndex = i18nsItems.findIndex(item => item?.languageTag === languageTag);

  if (organizationPrimaryLanguageIndex !== -1) {
    return i18nsItems[organizationPrimaryLanguageIndex] as AdminOrganizationsI18n;
  }

  // Fallback if does not match language but array
  return i18nsItems[0] as AdminOrganizationsI18n;
};

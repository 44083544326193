import { useTranslation } from 'next-i18next';
import * as React from 'react';

import ianaTimezones from '@/public/ianaTimeZones.json';

type TimeZoneParts = {
  abbreviation: string | undefined;
  displayName: string;
  ianaName: string;
  localName: string | undefined;
  offset: string | undefined;
};

/**
 * This hook dynamically imports a record of IANA time zones with names localized to the current locale.
 * Returns an object with records of this format: `IANATimeZone: "(Offset) Localized Name - IANATimeZone`".
 * Takes into account daylight savings of the locale at the current time, if applicable.
 * The display order is reversed in an rtl i18n context.
 */
export const useLocalizedTimeZones = () => {
  const { i18n } = useTranslation();
  const { currentLanguage, currentDirection } = React.useMemo(
    () => ({
      // @ts-expect-error: Property 'dir' does not exist on type 'i18n'
      currentDirection: i18n.dir(),
      // @ts-expect-error: Property 'language' does not exist on type 'i18n'
      currentLanguage: i18n.language,
    }),
    [i18n]
  );

  const [localizedTimeZones, setLocalizedTimezones] = React.useState<Record<string, TimeZoneParts>>({});

  const getTimezones = React.useCallback(
    async (language: string) => {
      // "America/Chicago": "(GMT-5) Central Daylight Time - America/Chicago"
      const timeZones = ianaTimezones.zones.reduce((acc: Record<string, TimeZoneParts>, ianaTimeZone) => {
        // Wrap this block in a try-catch in case some time zones are not supported by the environment's node version
        try {
          // "Central Daylight Time" (or "Central Standard Time" depending on the current time)
          const localName = new Intl.DateTimeFormat(language, { timeZone: ianaTimeZone, timeZoneName: 'long' })
            .formatToParts()
            .find(({ type }) => type === 'timeZoneName')?.value;

          // "GMT-5" (or "GMT-6" depending on the current time)
          const offset = new Intl.DateTimeFormat(language, { timeZone: ianaTimeZone, timeZoneName: 'short' })
            .formatToParts()
            .find(({ type }) => type === 'timeZoneName')?.value;

          const abbreviation = new Intl.DateTimeFormat(language, {
            timeZone: ianaTimeZone,
            timeZoneName: 'longGeneric',
          })
            .formatToParts()
            .find(({ type }) => type === 'timeZoneName')?.value;

          const displayName =
            currentDirection === 'rtl'
              ? `${ianaTimeZone} - ${localName} (${offset})`
              : `(${offset}) ${localName} - ${ianaTimeZone}`;

          acc[ianaTimeZone] = {
            abbreviation,
            displayName,
            ianaName: ianaTimeZone,
            localName,
            offset,
          };
        } catch (e) {
          return acc;
        }
        return acc;
      }, {});

      setLocalizedTimezones(timeZones);
    },
    [currentDirection]
  );

  React.useEffect(() => {
    const rootLanguage = new Intl.Locale(currentLanguage).language;
    (async () => {
      if (Intl.DateTimeFormat.supportedLocalesOf(currentLanguage).length > 0) {
        await getTimezones(currentLanguage);
      } else if (rootLanguage && Intl.DateTimeFormat.supportedLocalesOf(rootLanguage).length > 0) {
        await getTimezones(rootLanguage);
      } else {
        await getTimezones('en');
      }
    })();
  }, [currentLanguage, getTimezones]);

  return { localizedTimeZones };
};

import { getCookie } from 'cookies-next';
import { GetServerSidePropsContext, NextPageContext } from 'next';

import { APP_VERSION } from '../env';

/**
 * Will parse the `ssrYva` token from server side request headers and return formatted headers.
 *
 * @param context - The context value returned from `getServersideProps` or `getInitialProps`.
 *
 * @example
 * getInitialProps
 * ```typescript
 * import { getSSRHeaders } from '@/utils/getSSRHeaders';
 *
 * MyApp.getInitialProps = async (appContext: AppContext) => {
 *   const requestHeaders = getSSRHeaders(appContext.ctx);
 *   // ...
 * }
 * ```
 *
 * getServerSideProps
 * ```typescript
 * import { getSSRHeaders } from '@/utils/getSSRHeaders';
 *
 * export const getServerSideProps: GetServerSideProps = async (context) => {
 *   const requestHeaders = getSSRHeaders(context);
 *   // ...
 * };
 * ```
 */
export const getSSRHeaders = (context: GetServerSidePropsContext | NextPageContext) => {
  const ssrYva = getCookie('ssrYva', { req: context.req, res: context.res });

  const headers: HeadersInit = new Headers();
  headers.set('Authorization', `Bearer ${ssrYva}`);
  headers.set('content-type', 'application/json');
  headers.set('X-YouVersion-App-Platform', 'web');
  headers.set('X-YouVersion-App-Version', APP_VERSION);
  headers.set('X-YouVersion-Client', 'youversion-connect');
  return headers;
};

import parser from 'accept-language-parser';
import * as React from 'react';

import { getEnabledLocales } from '@/utils/locale-utils';

/**
 * Hook to be used on page components that returns the browser's selected language.
 *
 * @param acceptLanguage - HTTP request header that indicates the natural language and locale that the client prefers, used as a fallback for older browsers.
 *
 * @example
 * ```typescript
 * const { browserLanguage } = useDefaultBrowserLocale(acceptLanguage);
 * const { browserLanguage } = useDefaultBrowserLocale();
 * ```
 **/
export const useDefaultBrowserLocale = (acceptLanguage?: string) => {
  const [browserLanguage, setBrowserLanguage] = React.useState<string | null>(null);
  const enabledLocales = getEnabledLocales();

  React.useEffect(() => {
    if (!window?.navigator?.language?.includes('en-US' || 'en')) {
      setBrowserLanguage(window.navigator.language);
    } else if (acceptLanguage && !acceptLanguage.includes('en-US' || '*')) {
      // Fallback check for Firefox & IE
      const languageHeader = parser.pick(enabledLocales, acceptLanguage);
      setBrowserLanguage(languageHeader);
    }
  }, [acceptLanguage, enabledLocales]);
  return { browserLanguage };
};
